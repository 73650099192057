import clsx from "clsx";
import { useState } from "react";
import {
  Link,
  //  useLocation
} from "react-router-dom";

export default function NavLinks() {
  const [isProfileSubMenuOpen, setIsProfileSubMenuOpen] = useState(false);
  const [isServiceSubMenuOpen, setIsServiceSubMenuOpen] = useState(false);
  const [isBlogSubMenuOpen, setIsBlogSubMenuOpen] = useState(false);
  const [isOrderSubMenuOpen, setIsOrderSubMenuOpen] = useState(false);
  const [isUserSubMenuOpen, setIsUserSubMenuOpen] = useState(false);

  const toggleProfileSubMenu = () => {
    setIsProfileSubMenuOpen(!isProfileSubMenuOpen);
  };
  const toggleServiceSubMenu = () => {
    setIsServiceSubMenuOpen(!isServiceSubMenuOpen);
  };

  const toggleBlogSubMenu = () => {
    setIsBlogSubMenuOpen(!isBlogSubMenuOpen);
  };

  const toggleOrderSubMenu = () => {
    setIsOrderSubMenuOpen(!isOrderSubMenuOpen);
  };
  const toggleUserSubMenu = () => {
    setIsUserSubMenuOpen(!isUserSubMenuOpen);
  };
  // const { pathname } = useLocation();
  return (
    <>
      {/* <LinkIcon className="w-6" /> */}
      {/* <p className="hidden py-[16px] px-[10px] md:flex items-center !mb-0 text-[#7D7D7D] hover:text-[#131313] cursor-pointer"></p> */}
      <div className="tln-sidebar">
        <nav className="sidebar-nav">
          <ul>
            <li
              className={`has-sub-menu ${isProfileSubMenuOpen ? "open" : ""}`}
            >
              <Link
                to="#"
                onClick={toggleProfileSubMenu}
                className={isProfileSubMenuOpen ? "active" : ""}
              >
                <div className="menu-item-text">Profile</div>
                <div className="menu-item-arrow-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="9"
                    height="6"
                    viewBox="0 0 9 6"
                    fill="none"
                  >
                    <path
                      d="M1 1L4.5 4L8 1"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                  </svg>
                </div>
              </Link>
              <ul className={`sub-menu ${isProfileSubMenuOpen ? "open" : ""}`}>
                <li>
                  <Link
                    to={"/admin-pannel/profile"}
                    // onClick={handleShowServiceModal}
                    data-bs-toggle="modal"
                    data-bs-target="#Service-modal"
                  >
                    <div className="menu-item-text">View Profile</div>
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/admin-pannel/editprofile"}
                    data-bs-toggle="modal"
                    data-bs-target="#Service-modal"
                  >
                    <div className="menu-item-text">Edit Profile</div>
                  </Link>
                </li>
              </ul>
            </li>
            <li
              className={`has-sub-menu ${isServiceSubMenuOpen ? "open" : ""}`}
            >
              <Link
                to="#"
                onClick={toggleServiceSubMenu}
                className={isServiceSubMenuOpen ? "active" : ""}
              >
                <div className="menu-item-text">Services</div>
                <div className="menu-item-arrow-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="9"
                    height="6"
                    viewBox="0 0 9 6"
                    fill="none"
                  >
                    <path
                      d="M1 1L4.5 4L8 1"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                  </svg>
                </div>
              </Link>
              <ul className={`sub-menu ${isServiceSubMenuOpen ? "open" : ""}`}>
                <li>
                  <Link
                    to={"/admin-pannel/add-service"}
                    // onClick={handleShowServiceModal}
                    data-bs-toggle="modal"
                    data-bs-target="#Service-modal"
                  >
                    <div className="menu-item-text">Add Services</div>
                  </Link>
                </li>{" "}
                <li>
                  <Link
                    to={"/admin-pannel/all-services"}
                    // onClick={handleShowServiceModal}
                    data-bs-toggle="modal"
                    data-bs-target="#Service-modal"
                  >
                    <div className="menu-item-text">List OF All Services</div>
                  </Link>
                </li>{" "}
              </ul>
            </li>
            <li className={`has-sub-menu ${isBlogSubMenuOpen ? "open" : ""}`}>
              <Link
                to="#"
                onClick={toggleBlogSubMenu}
                className={isBlogSubMenuOpen ? "active" : ""}
              >
                <div className="menu-item-text">Blogs</div>
                <div className="menu-item-arrow-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="9"
                    height="6"
                    viewBox="0 0 9 6"
                    fill="none"
                  >
                    <path
                      d="M1 1L4.5 4L8 1"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                  </svg>
                </div>
              </Link>
              <ul className={`sub-menu ${isBlogSubMenuOpen ? "open" : ""}`}>
                <li>
                  <Link
                    to={"/admin-pannel/create-blog"}
                    // onClick={handleShowServiceModal}
                    data-bs-toggle="modal"
                    data-bs-target="#Service-modal"
                  >
                    <div className="menu-item-text">Add Blogs</div>
                  </Link>
                </li>{" "}
                <li>
                  <Link
                    to={"/admin-pannel/all-blogs"}
                    // onClick={handleShowServiceModal}
                    data-bs-toggle="modal"
                    data-bs-target="#Service-modal"
                  >
                    <div className="menu-item-text">List OF All Blogs</div>
                  </Link>
                </li>{" "}
              </ul>
            </li>
            <li className={`has-sub-menu ${isOrderSubMenuOpen ? "open" : ""}`}>
              <Link
                to="#"
                onClick={toggleOrderSubMenu}
                className={isOrderSubMenuOpen ? "active" : ""}
              >
                <div className="menu-item-text">Orders</div>
                <div className="menu-item-arrow-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="9"
                    height="6"
                    viewBox="0 0 9 6"
                    fill="none"
                  >
                    <path
                      d="M1 1L4.5 4L8 1"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                  </svg>
                </div>
              </Link>
              <ul className={`sub-menu ${isOrderSubMenuOpen ? "open" : ""}`}>
                <li>
                  <Link
                    to={"/admin-pannel/orderlist"}
                    data-bs-toggle="modal"
                    data-bs-target="#Service-modal"
                  >
                    <div className="menu-item-text">List OF All Order</div>
                  </Link>
                </li>{" "}
              </ul>
            </li>
            <li className={`has-sub-menu ${isUserSubMenuOpen ? "open" : ""}`}>
              <Link
                to="#"
                onClick={toggleUserSubMenu}
                className={isUserSubMenuOpen ? "active" : ""}
              >
                <div className="menu-item-text">Users</div>
                <div className="menu-item-arrow-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="9"
                    height="6"
                    viewBox="0 0 9 6"
                    fill="none"
                  >
                    <path
                      d="M1 1L4.5 4L8 1"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                  </svg>
                </div>
              </Link>
              <ul className={`sub-menu ${isUserSubMenuOpen ? "open" : ""}`}>
                <li>
                  <Link
                    to={"/admin-pannel/userlist"}
                    data-bs-toggle="modal"
                    data-bs-target="#Service-modal"
                  >
                    <div className="menu-item-text">List OF All Users</div>
                  </Link>
                </li>{" "}
              </ul>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
}
