import { takeLatest, put, call } from "redux-saga/effects";
import axios from "axios";
import toast from "react-hot-toast";
import { baseUrl } from "./apiUrl";
import {
  addOrderRequest,
  addOrderSuccess,
  addOrderFailure,
  getAllOrderRequest,
  getAllOrderSuccess,
  getAllOrderFailure,
  getSingleOrderRequest,
  getSingleOrderSuccess,
  getSingleOrderFailure,
  updateOrderRequest,
  updateOrderSuccess,
  updateOrderFailure,
  deleteOrderRequest,
  deleteOrderSuccess,
  deleteOrderFailure,
} from "../slice/orderSlice";
function* addOrder(action) {
  try {
    console.log("payload :", action.payload);
    const response = yield call(
      axios.post,
      `${baseUrl}/api/v1/Order/createorder`,
      action.payload
    );
    yield put(addOrderSuccess(response.data));
    console.log("Order :", response.data);
  } catch (error) {
    yield put(addOrderFailure(error.message));
  }
}
function* getAllOrderWorker() {
  try {
    // console.log("Saga Clicked Order");
    const response = yield call(
      axios.get,
      `${baseUrl}/api/v1/Order/getallorder`
    );
    yield put(getAllOrderSuccess(response.data));
    console.log("All Order are :", response.data);
  } catch (error) {
    yield put(getAllOrderFailure(error.message));
  }
}

function* getSingleOrderWorker(action) {
  try {
    const id = action.payload;
    console.log("saga id is :", id);
    const response = yield call(
      axios.get,
      `${baseUrl}/api/v1/Order/getsingleuserorders/${id}`
    );
    yield put(getSingleOrderSuccess(response.data));
    console.log(" Single Order is  :", response.data);
  } catch (error) {
    yield put(getSingleOrderFailure(error.message));
  }
}
function* updateOrder(action) {
  try {
    const { status, id } = action.payload;
    console.log("Update Order", action.payload);
    console.log("id :", id);
    console.log("status :", status);
    const response = yield call(
      axios.put,
      `${baseUrl}/api/v1/order/updateorderstatus/${id}`,
      { status }
    );
    yield put(updateOrderSuccess(response.data));
  } catch (error) {
    put(updateOrderFailure(error.message));
  }
}
function* deleteOrderWorker(action) {
  try {
    const id = action.payload;
    yield call(axios.delete, `${baseUrl}/api/v1/order/deleteorder/${id}`);
    yield put(deleteOrderSuccess());
  } catch (error) {
    yield put(deleteOrderFailure(error.message));
  }
}
function* watchAddOrder() {
  yield takeLatest(addOrderRequest.type, addOrder);
}
function* watchGetAllOrder() {
  yield takeLatest(getAllOrderRequest.type, getAllOrderWorker);
}
function* watchGetSingleOrder() {
  yield takeLatest(getSingleOrderRequest.type, getSingleOrderWorker);
}
function* watchUpdateOrder() {
  yield takeLatest(updateOrderRequest.type, updateOrder);
}
function* watchDeleteOrder() {
  yield takeLatest(deleteOrderRequest.type, deleteOrderWorker);
}
export {
  watchAddOrder,
  watchGetAllOrder,
  watchUpdateOrder,
  watchGetSingleOrder,
  watchDeleteOrder,
};
