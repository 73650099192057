import React, { useState, useCallback, useEffect, useMemo } from "react";
import JoditEditor from "jodit-react";
import { useNavigate } from "react-router-dom";
import { UseSelector, useSelector } from "react-redux/es/hooks/useSelector";
import toast from "react-hot-toast";

import {
  updateServiceRequest,
  getAllServiceRequest,
} from "../../../../redux/slice/serviceSlice";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Buffer } from "buffer";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { CircularProgress } from "@mui/material";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
const EditServiceComp = () => {
  let { state } = useLocation();
  const navigate = useNavigate();

  let cardData = state?.item;

  const categories = cardData?.categories;

  const dispatch = useDispatch();

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [file, setFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [bannerimgPreview, setBannerimgPreview] = useState(null);
  const [bannerimgFile, setBannerimgFile] = useState(null);
  const [content, setContent] = useState(cardData?.content || "");
  const [logs, setLogs] = useState([]);
  const [categoriesData, setCategoriesData] = useState([]);
  const [submitting, setSubmitting] = useState(false);

  const [successfullShowModal, setSuccessfullShowModal] = useState(false);
  var successMessage = useSelector((state) => state.services?.successMessage);

  const successfullModalhandleClose = () => {
    setSuccessfullShowModal(false);

    dispatch(getAllServiceRequest());
    setTimeout(() => {
      navigate("/admin-pannel/all-services");
    }, 500);
  };
  const BannerphotoData = cardData?.Bannerphoto
    ? `data:${cardData.Bannerphoto.contentType};base64,${Buffer.from(
        cardData.Bannerphoto.data
      ).toString("base64")}`
    : null;
  const cardphotoData = cardData?.cardphoto
    ? `data:${cardData.cardphoto.contentType};base64,${Buffer.from(
        cardData.cardphoto.data
      ).toString("base64")}`
    : null;
  console.log("photo isss :", cardphotoData);
  useEffect(() => {
    setName(cardData?.name);
    setImagePreview(cardphotoData);
    setDescription(cardData?.description);
    setBannerimgPreview(BannerphotoData);
    setContent(cardData?.content);
    setCategoriesData(categories);
  }, []);

  const appendLog = useCallback(
    (message) => {
      const newLogs = [...logs, message];
      setLogs(newLogs);
    },
    [logs, setLogs]
  );
  const config = useMemo(
    () => ({
      readonly: false,
      height: 400,
      toolbar: true,
      spellcheck: true,
      language: "en",
      toolbarButtonSize: "medium",
      toolbarAdaptive: false,
      showCharsCounter: true,
      showWordsCounter: true,
      showXPathInStatusbar: false,
      askBeforePasteHTML: true,
      askBeforePasteFromWord: true,
      defaultActionOnPaste: "insert_clear_html",
      buttons: [
        "bold",
        "italic",
        "underline",
        "strikethrough",
        // "ul",
        // "ol",
        "indent",
        "outdent",
        "font",
        "fontsize",
        "brush",
        "paragraph",
        "align",
        "undo",
        "redo",
        "cut",
        "copy",
        "paste",
        "table",
        "link",
        "unlink",
        "hr",
        "symbol",
        "fullsize",
        "print",
        "about",
      ],
    }),
    []
  );
  const onChange = useCallback(
    (newContent) => {
      appendLog(`onChange triggered with ${newContent}`);
    },
    [appendLog]
  );
  const onBlur = useCallback(
    (newContent) => {
      appendLog(`onBlur triggered with ${newContent}`);
      setContent(newContent);
    },
    [appendLog, setContent]
  );
  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      setSubmitting(true);
      const formData = new FormData();
      formData.append("name", name);
      formData.append("cardphoto", file);
      formData.append("description", description);
      formData.append("Bannerphoto", bannerimgFile);
      formData.append("content", content);
      formData.append("categories", JSON.stringify(categoriesData));

      await dispatch(
        updateServiceRequest({ formdata: formData, id: cardData._id })
      );
      setSuccessfullShowModal(true);
    } catch (error) {
      setSubmitting(false);
    }
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleDiscChange = (event) => {
    setDescription(event.target.value);
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreview(reader.result);
    };
    reader.readAsDataURL(selectedFile);
  };

  const handleBannerImgChange = (event) => {
    const selectedFile = event.target.files[0];
    setBannerimgFile(selectedFile);
    const reader = new FileReader();
    reader.onloadend = () => {
      setBannerimgPreview(reader.result);
    };
    reader.readAsDataURL(selectedFile);
  };
  const handleCategoryData = (event, editableCatindex) => {
    const updatedCategories = [...categoriesData];
    updatedCategories[editableCatindex] = {
      ...updatedCategories[editableCatindex],
      name: event.target.value,
    };
    setCategoriesData(updatedCategories);
  };
  // const handleSubCategoryName = (event, categoryIndex, subCategoryIndex) => {
  //   const updatedCategoryData = [...categoriesData];
  //   const updatedSubCategories = [
  //     ...updatedCategoryData[categoryIndex]?.subcategories,
  //   ];
  //   updatedSubCategories[subCategoryIndex] = {
  //     ...updatedSubCategories[subCategoryIndex],
  //     name: event?.target?.value,
  //   };
  //   setCategoriesData(updatedCategoryData);
  //   console.log("Updated Sub Categories", updatedCategoryData);
  // };
  // const handleSubCategoryPrice = (event, categoryIndex, subCategoryIndex) => {
  //   const updatedCategoryData = [...categoriesData];
  //   const updatedSubCategories = [
  //     ...updatedCategoryData[categoryIndex]?.subcategories,
  //   ];
  //   updatedSubCategories[subCategoryIndex] = {
  //     ...updatedSubCategories[subCategoryIndex],
  //     price: event?.target?.value,
  //   };
  //   setCategoriesData(updatedCategoryData);
  //   console.log("Updated Price", updatedCategoryData);
  // };

  // const handleSubCategoryName = (event, categoryIndex, subCategoryIndex) => {
  //   const updatedCategoryData = [...categoriesData];
  //   const updatedSubCategories = [
  //     ...updatedCategoryData[categoryIndex]?.subcategories,
  //   ];
  //   updatedSubCategories[subCategoryIndex] = {
  //     ...updatedSubCategories[subCategoryIndex],
  //     name: event?.target?.value,
  //   };
  //   updatedCategoryData[categoryIndex].subcategories = updatedSubCategories;
  //   setCategoriesData(updatedCategoryData);
  //   console.log("Updated Sub Categories", updatedCategoryData);
  // };

  // const handleSubCategoryPrice = (event, categoryIndex, subCategoryIndex) => {
  //   const updatedCategoryData = [...categoriesData];
  //   const updatedSubCategories = [
  //     ...updatedCategoryData[categoryIndex]?.subcategories,
  //   ];
  //   updatedSubCategories[subCategoryIndex] = {
  //     ...updatedSubCategories[subCategoryIndex],
  //     price: event?.target?.value,
  //   };
  //   updatedCategoryData[categoryIndex].subcategories = updatedSubCategories;
  //   setCategoriesData(updatedCategoryData);
  //   console.log("Updated Price", updatedCategoryData);
  // };
  const handleSubCategoryName = (event, categoryIndex, subCategoryIndex) => {
    const updatedCategoryData = [...categoriesData];
    const updatedSubCategories = [
      ...updatedCategoryData[categoryIndex]?.subcategories,
    ];

    updatedSubCategories[subCategoryIndex] = {
      ...updatedSubCategories[subCategoryIndex],
      name: event?.target?.value,
    };

    updatedCategoryData[categoryIndex] = {
      ...updatedCategoryData[categoryIndex],
      subcategories: updatedSubCategories,
    };

    setCategoriesData(updatedCategoryData);
  };

  const handleSubCategoryPrice = (event, categoryIndex, subCategoryIndex) => {
    const updatedCategoryData = [...categoriesData];
    const updatedSubCategories = [
      ...updatedCategoryData[categoryIndex]?.subcategories,
    ];

    updatedSubCategories[subCategoryIndex] = {
      ...updatedSubCategories[subCategoryIndex],
      price: event?.target?.value,
    };

    updatedCategoryData[categoryIndex] = {
      ...updatedCategoryData[categoryIndex],
      subcategories: updatedSubCategories,
    };

    setCategoriesData(updatedCategoryData);
  };

  return (
    <>
      <div className="addServiceContainer">
        <div className="serviceForm">
          <form onSubmit={handleSubmit} encType="multipart/form-data">
            <div className="formContainer flex flex-col items-start gap-[20px]">
              <h1 className="text-[22px]">Service Card Data</h1>

              <div className="name flex flex-col gap-[5px] w-full">
                <div className="text-[14px]">Edit service name </div>
                <textarea
                  type="text"
                  // placeholder="Enter Name ..."
                  value={name}
                  onChange={handleNameChange}
                  className="w-full"
                />
              </div>
              <div className="cardDetails flex flex-col gap-[5px] w-full">
                <div className="text-[14px]">
                  Edit Service Description (Card/detail Pg){" "}
                </div>
                <textarea
                  type="text"
                  placeholder="Enter Description ..."
                  value={description}
                  onChange={handleDiscChange}
                  className="w-full"
                  rows={5}
                />
              </div>
              <div className="cardImg flex flex-col w-full">
                {imagePreview && (
                  <div className="selectedImg md:w-1/3">
                    <img src={imagePreview} alt="Selected" />
                  </div>
                )}
                <label htmlFor="fileInput" className="fileLabel cursor-pointer">
                  {!imagePreview && (
                    <div className="addText text-[14px]">Add Card Image</div>
                  )}
                  <div className="addImg text-[50px]">+</div>
                </label>
                <input
                  type="file"
                  id="fileInput"
                  accept=".png, .svg, .jpg, .jpeg"
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                />
              </div>

              <div className="descriptionPage">
                <h1 className="text-[22px]">Detail Page Data</h1>
                <div className="bannerImg flex flex-col w-full">
                  {bannerimgPreview && (
                    <div className="selectedImg md:w-full">
                      <img src={bannerimgPreview} alt="Selected" />
                    </div>
                  )}
                  <label
                    htmlFor="bannerfileInput"
                    className="fileLabel cursor-pointer"
                  >
                    {!bannerimgPreview && (
                      <div className="addText text-[14px]">
                        Add Banner Image
                      </div>
                    )}
                    <div className="addImg text-[50px]">+</div>
                  </label>
                  <input
                    type="file"
                    id="bannerfileInput"
                    accept=".png, .svg, .jpg, .jpeg"
                    onChange={handleBannerImgChange}
                    style={{ display: "none" }}
                  />
                </div>
                <div className="serviceDetails flex flex-col gap-[5px]">
                  <div>Add Service Details</div>
                  <JoditEditor
                    value={content}
                    config={config}
                    tabIndex={1}
                    onBlur={onBlur}
                    onChange={onChange}
                  />
                </div>
                <div className="serviceCategories py-5">
                  <h1 className="text-[22px]">
                    Service Categories With Sub Categories and Prices
                  </h1>
                  {categoriesData?.map((categoryData, categoryIndex) => (
                    <div>
                      <div key={categoryIndex}>
                        <input
                          value={categoryData?.name}
                          onChange={(event) =>
                            handleCategoryData(event, categoryIndex)
                          }
                        />
                      </div>
                      <div className="subcategories flex flex-col py-3 gap-y-3">
                        {categoryData?.subcategories?.map(
                          (subCategoryData, subCategoryIndex) => (
                            <div className="subCategory flex gap-2">
                              <div className="subCatName flex">
                                <div className="text-[12px]">
                                  Sub Category :{" "}
                                </div>
                                <input
                                  id="subcatName"
                                  value={subCategoryData?.name}
                                  onChange={(event) =>
                                    handleSubCategoryName(
                                      event,
                                      categoryIndex,
                                      subCategoryIndex
                                    )
                                  }
                                />
                              </div>
                              <div className="subCatPrice flex">
                                <div className="text-[12px]">Price : </div>

                                <input
                                  value={subCategoryData?.price}
                                  onChange={(event) =>
                                    handleSubCategoryPrice(
                                      event,
                                      categoryIndex,
                                      subCategoryIndex
                                    )
                                  }
                                />
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="submitService pt-3">
              <div
                className="submitBtn cursor-pointer bg-primary text-white px-5 py-2 w-[150px] text-center rounded"
                onClick={handleSubmit}
              >
                {submitting ? "Submiting ..." : "Submit"}
              </div>
            </div>
          </form>
        </div>
      </div>
      <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={successfullShowModal}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          <p className="font-bold "> Updating Service Data</p>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={successfullModalhandleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          {successMessage ? (
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ minWidth: 400, minHeight: 70 }}
            >
              <h1 className="text-primary text-center mt-5 text-2xl font-bold">
                Service Updated successfully!
              </h1>
            </div>
          ) : (
            <div
              className="flex-col "
              style={{ minWidth: 400, minHeight: 100 }}
            >
              <h6 className="text-2xl    text-center mt-4">
                Updating Service Data
              </h6>
              <p className="text-center my-3">
                {/* <Stack sx={{ color: "grey.500" }} spacing={2} direction="row"> */}
                <CircularProgress color="success" />
              </p>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="success"
            autoFocus
            onClick={successfullModalhandleClose}
          >
            Ok
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
};

export default EditServiceComp;
