import React, { useEffect, useState } from "react";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteOrderRequest,
  getAllOrderRequest,
  updateOrderRequest,
} from "../../../redux/slice/orderSlice";
import { CircularProgress } from "@mui/material";
import { DialogContentText } from "@mui/material";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const columns = [
  { id: "count", label: "#", minWidth: 50 },
  { id: "_id", label: "OrderId", minWidth: 50 },
  { id: "name", label: "CreatedBy", minWidth: 50 },
  { id: "email", label: "Email", minWidth: 50 },
  { id: "phone", label: "Phone", minWidth: 50 },
  { id: "address", label: "Address", minWidth: 50 },
  { id: "city", label: "City", minWidth: 50 },
  { id: "postcode", label: "PostalCode", minWidth: 50 },
  { id: "status", label: "Status", minWidth: 50 },
  { id: "amount", label: "Amount", minWidth: 50 },
  { id: "action", label: "Action", minWidth: 50 },
  // Add more columns as needed
];

const OrderList = () => {
  const dispatch = useDispatch();
  const AllordesrData = useSelector((state) => state.orders.orders);

  const [successfullShowModalDel, setSuccessfullShowModalDel] = useState(false);
  const [successfullShowModalEdit, setSuccessfullShowModalEdit] =
    useState(false);
  const successMessage = useSelector((state) => state.orders.successMessage);
  const [status, setStatus] = useState("");

  const handleChange = (e) => {
    setStatus(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(status);
    // dispatch(updateOrderStatus({ orderId, status }));
    // Reset the status after submission
    setStatus("");
  };
  const successfullModalhandleCloseDel = () => {
    dispatch(getAllOrderRequest());
    setSuccessfullShowModalDel(false);
  };
  const successfullModalhandleCloseEdit = () => {
    dispatch(getAllOrderRequest());
    setSuccessfullShowModalEdit(false);
  };

  const [opendel, setOpenDel] = React.useState(false);

  const [openedit, setOpenEdit] = React.useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [editId, setEditId] = useState(null);

  const handleClickOpenDel = (id) => {
    setDeleteId(id);
    setOpenDel(true);
  };
  const handleClickOpenEdit = (id) => {
    setEditId(id);
    setOpenEdit(true);
  };

  const handleCloseDel = () => {
    setOpenDel(false);
  };
  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const handleDelete = (id) => {
    dispatch(deleteOrderRequest(id));
    setOpenDel(false);
    setSuccessfullShowModalDel(true);
  };

  const handleEdit = () => {
    const id = editId;
    console.log("Edit order id is ", editId);
    console.log("Edit order status is ", status);
    dispatch(updateOrderRequest({ status, id }));
    setOpenEdit(false);
    setSuccessfullShowModalEdit(true);
  };

  useEffect(() => {
    dispatch(getAllOrderRequest());
  }, [dispatch]);

  return (
    <>
      <div>
        <div className="text-center">
          <h6 className="text-4xl font-bold my-10">List of All Orders</h6>
        </div>
        {AllordesrData ? (
          <>
            <div className="flex justify-center">
              <Paper sx={{ width: "80%", overflow: "hidden" }}>
                <TableContainer sx={{ maxHeight: 500 }}>
                  <Table className=" " stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            align="left" // Align the headers to the left
                            style={{
                              minWidth: column.minWidth,
                              backgroundColor: "#46B035",
                              color: "white",
                              fontSize: "16px",
                              border: "1px solid #ddd",
                            }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {AllordesrData?.map((order, index) => (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={order._id}
                        >
                          {columns.map((column) => {
                            const value = order[column.id];
                            return (
                              <TableCell
                                key={column.id}
                                align="left"
                                style={{
                                  border: "1px solid #ddd",
                                }}
                              >
                                {column.id === "count" ? (
                                  index + 1
                                ) : column.id === "action" ? (
                                  <div className="flex">
                                    <button
                                      className=" p-1 m-2 rounded-lg"
                                      onClick={() =>
                                        handleClickOpenEdit(order._id)
                                      }
                                      style={{
                                        backgroundColor: "#46B035",
                                        color: "white",
                                        fontSize: "16px",
                                        border: "1px solid #ddd",
                                      }}
                                    >
                                      Edit Status
                                    </button>
                                    <button
                                      className="p-1 m-2 rounded-md"
                                      style={{
                                        backgroundColor: "red",
                                        color: "white",
                                        fontSize: "16px",
                                        border: "1px solid #ddd",
                                      }}
                                      onClick={() =>
                                        handleClickOpenDel(order._id)
                                      }
                                    >
                                      Delete
                                    </button>
                                  </div>
                                ) : (
                                  value
                                )}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </div>
          </>
        ) : (
          <>
            <div className=" flex-col flex justify-content-center align-content-center text-center">
              <h1 className="text-center text-2xl">Loading Order List...</h1>
              <span className="text-center">
                {" "}
                <CircularProgress color="success" />
              </span>

              <br />
            </div>
          </>
        )}
      </div>

      <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={successfullShowModalEdit}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          <p className="font-bold "> Edit Order</p>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={successfullModalhandleCloseEdit}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          {successMessage ? (
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ minWidth: 400, minHeight: 70 }}
            >
              <h1 className="text-primary text-center mt-5 text-2xl font-bold">
                Order Status Updated successfully!
              </h1>
            </div>
          ) : (
            <div
              className="flex-col "
              style={{ minWidth: 500, minHeight: 500 }}
            >
              <h6 className="text-2xl    text-center mt-4">
                Updating Order Status
              </h6>
              <p className="text-center my-3">
                {/* <Stack sx={{ color: "grey.500" }} spacing={2} direction="row"> */}
                <CircularProgress color="success" />
              </p>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="success"
            autoFocus
            onClick={successfullModalhandleCloseEdit}
          >
            Ok
          </Button>
        </DialogActions>
      </BootstrapDialog>
      <Dialog
        open={openedit}
        onClose={handleCloseEdit}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"User Confirmation needed"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div
              className="border border-gray-300 rounded-lg p-4"
              style={{
                minWidth: 500,
                minHeight: 200,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <h3 className=" text-primary text-2xl">Update Order Status</h3>
              <form onSubmit={handleSubmit} style={{ flex: 1 }}>
                <div className=" ">
                  <label className="ml-10 flex flex-col text-2xl text-black">
                    Select Status:
                    <select value={status} onChange={handleChange}>
                      <option value="">Select status</option>
                      <option value="pending">Pending</option>
                      <option value="processing">Processing</option>
                      <option value="completed">Completed</option>
                    </select>
                  </label>
                </div>
                <div className="mt-20 ml-5">
                  <button
                    type="button"
                    className="mt-auto border border-1 text-white bg-primary rounded-lg p-2"
                    style={{ alignSelf: "flex-end" }}
                    onClick={handleEdit}
                  >
                    Update Status
                  </button>
                </div>
              </form>
            </div>
          </DialogContentText>
        </DialogContent>
        {/* <DialogActions>
          <Button onClick={handleCloseEdit}>Disagree</Button>
          <Button onClick={() => handleEdit(editId)} autoFocus>
            Agree
          </Button>
        </DialogActions> */}
      </Dialog>

      <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={successfullShowModalDel}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          <p className="font-bold "> Delete Order</p>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={successfullModalhandleCloseDel}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          {successMessage ? (
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ minWidth: 400, minHeight: 70 }}
            >
              <h1 className="text-primary text-center mt-5 text-2xl font-bold">
                Order Deleted successfully!
              </h1>
            </div>
          ) : (
            <div
              className="flex-col "
              style={{ minWidth: 400, minHeight: 100 }}
            >
              <h6 className="text-2xl    text-center mt-4">Deleting Order</h6>
              <p className="text-center my-3">
                {/* <Stack sx={{ color: "grey.500" }} spacing={2} direction="row"> */}
                <CircularProgress color="success" />
              </p>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="success"
            autoFocus
            onClick={successfullModalhandleCloseDel}
          >
            Ok
          </Button>
        </DialogActions>
      </BootstrapDialog>
      <Dialog
        open={opendel}
        onClose={handleCloseDel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"User Confirmation needed"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are You sure you want to delete this Order?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDel}>Disagree</Button>
          <Button onClick={() => handleDelete(deleteId)} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default OrderList;
