import React from "react";
import AddServiceComp from "../../../../components/AdminPannelComp/AddServiceComp";
const AddService = () => {
  return (
    <div>
      <AddServiceComp />
    </div>
  );
};

export default AddService;
