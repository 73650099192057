import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

const PickUp = ({ pickupData }) => {
  function generateDates() {
    const today = new Date();
    const dates = [];
    dates.push(today.toISOString().split("T")[0]);
    for (let i = 1; i <= 6; i++) {
      const nextDay = new Date();
      nextDay.setDate(today.getDate() + i);
      dates.push(nextDay.toISOString().split("T")[0]);
    }
    return dates;
  }
  function formatDate(dateString) {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", { month: "short", day: "numeric" });
  }
  function getDayName(dateString) {
    const date = new Date(dateString);
    const dayIndex = date.getDay();
    const daysOfWeek = [
      "Sun. ",
      "Mon. ",
      "Tue. ",
      "Wed. ",
      "Thu. ",
      "Fri. ",
      "Sat. ",
    ];
    return daysOfWeek[dayIndex];
  }
  const dateArray = generateDates();

  const scheduleData = [
    {
      date: dateArray[0],
      slots: ["8:00-10:00 am", "10:00-12:00 pm", "12:00-15:00 pm"],
    },
    {
      date: dateArray[1],
      slots: ["8:00-10:00 am", "10:00-12:00 pm", "12:00-15:00 pm"],
    },
    {
      date: dateArray[2],
      slots: ["8:00-10:00 am", "10:00-12:00 pm", "12:00-15:00 pm"],
    },
    {
      date: dateArray[3],
      slots: ["8:00-10:00 am", "10:00-12:00 pm", "12:00-15:00 pm"],
    },
    {
      date: dateArray[4],
      slots: ["8:00-10:00 am", "10:00-12:00 pm", "12:00-15:00 pm"],
    },
    {
      date: dateArray[5],
      slots: ["8:00-10:00 am", "10:00-12:00 pm", "12:00-15:00 pm"],
    },
  ];

  const [selectedPickupDate, setselectedPickupDate] = useState(
    scheduleData[0].date
  );
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
  // console.log("Se -- selectedPickupDate", selectedPickupDate);
  // console.log("Se -- selectedTime", selectedTimeSlot);
  return (
    <div className="bg-white shadow-lg rounded-md  mx-auto px-8 py-6 my-10 relative">
      <div className="mb-10">
        <h4 className="text-xl pb-2">Please choose a pick up slot</h4>
        <p className="pm text-sm text-content ">
          Our driver will pick up your bag of dry cleaning, tailoring and/or
          wash & fold.
        </p>
      </div>

      <div
        className="schedule-container animation-fade-in"
        style={{ position: "relative" }}
      >
        <Swiper
          // slidesPerView={4}
          navigation={true}
          modules={[Navigation]}
          breakpoints={{
            640: {
              slidesPerView: 2,
            },
            768: {
              slidesPerView: 4,
            },
            1024: {
              slidesPerView: 4,
            },
          }}
        >
          {scheduleData.map((item, index) => (
            <SwiperSlide key={index}>
              <div className="text-base text-black pb py-5 ">
                {/* {item.date} */}
                {getDayName(item.date)}
                {formatDate(item.date)}
              </div>
              <div className="slots">
                {item.slots.map((pickupSlot, slotIndex) => (
                  <div
                    key={slotIndex}
                    className={`slot text-base text-secondary px-6 py-3 m-3 bg-content bg-opacity-5 rounded-md cursor-pointer pm ${
                      selectedPickupDate === item.date &&
                      selectedTimeSlot === pickupSlot
                        ? "selected bg-content bg-opacity-75 text-white pm"
                        : ""
                    }`}
                    onClick={() => {
                      setselectedPickupDate(item.date);
                      setSelectedTimeSlot(pickupSlot);
                      pickupData({ selectedPickupDate, pickupSlot });
                    }}
                  >
                    {pickupSlot}
                  </div>
                ))}
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default PickUp;
