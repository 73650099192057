import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {
    users: [],
    user: null,
    loading: false,
    error: null,
    successMessage: false,
  },
  reducers: {
    registerStart(state) {
      state.loading = true;
    },
    registerSuccess(state, action) {
      state.loading = false;
      state.user = action.payload;
      state.successMessage = false;
    },
    registerFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    signInStart(state) {
      state.loading = true;
    },
    signInSuccess(state, action) {
      state.loading = false;
      state.user = action.payload;
      state.successMessage = true;
    },
    signInFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    getAllUserRequest: (state) => {
      state.loading = true;
      state.error = null;
    },
    getAllUserSuccess: (state, action) => {
      state.loading = false;
      state.users = action.payload;
      state.error = null;
    },
    getAllUserFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    getSingleUserRequest: (state) => {
      state.loading = true;
      state.error = null;
    },
    getSingleUserSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.success = true;
      state.user = action.payload;
    },
    getSingleUserFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    updateUserRequest: (state) => {
      state.loading = true;
      state.error = null;
      state.success = false;
      state.successMessage = false;
    },
    updateUserSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.success = true;
      state.user = action.payload;
      state.successMessage = true;
    },
    updateUserFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    resetPasswordStart(state) {
      state.loading = true;
    },
    resetPasswordSuccess(state, action) {
      state.loading = false;
      state.user = action.payload;
    },
    resetPasswordFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    newPasswordStart(state) {
      state.loading = true;
    },
    newPasswordSuccess(state, action) {
      state.loading = false;
      state.user = action.payload;
    },
    newPasswordFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    forgotPasswordStart(state) {
      state.loading = true;
    },
    forgotPasswordSuccess(state, action) {
      state.loading = false;
      state.user = action.payload;
    },
    forgotPasswordFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    verifyCodeStart(state) {
      state.loading = true;
    },
    verifyCodeSuccess(state, action) {
      state.loading = false;
      state.user = action.payload;
    },
    verifyCodeFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  registerStart,
  registerSuccess,
  registerFailure,
  signInStart,
  signInSuccess,
  signInFailure,

  updateUserRequest,
  updateUserSuccess,
  updateUserFailure,

  resetPasswordStart,
  resetPasswordSuccess,
  resetPasswordFailure,

  newPasswordStart,
  newPasswordSuccess,
  newPasswordFailure,

  forgotPasswordStart,
  forgotPasswordSuccess,
  forgotPasswordFailure,

  verifyCodeStart,
  verifyCodeSuccess,
  verifyCodeFailure,

  getAllUserRequest,
  getAllUserSuccess,
  getAllUserFailure,
  getSingleUserRequest,
  getSingleUserSuccess,
  getSingleUserFailure,
} = userSlice.actions;
export default userSlice.reducer;
