import { all } from "redux-saga/effects";

import {
  watchRegisterUser,
  watchSignInUser,
  watchResetPassword,
  watchGetSingleUser,
  watchGetAllUser,
  watchUpdateUser,
  watchNewPassword,
} from "./userSaga";
import {
  watchAddService,
  watchGetAllService,
  watchGetSingleService,
  watchUpdateService,
  watchDeleteService,
} from "./serviceSaga";
import {
  watchAddBlog,
  watchGetAllBlogs,
  watchGetSingleBlogs,
  watchUpdateBlog,
  watchDeleteBlog,
} from "./blogSaga";
import { watchAddShipment } from "./shipmentSaga";
import {
  watchAddOrder,
  watchGetAllOrder,
  watchUpdateOrder,
  watchGetSingleOrder,
  watchDeleteOrder,
} from "./orderSaga";
export default function* rootSaga() {
  yield all([
    watchRegisterUser(),
    watchSignInUser(),
    watchGetSingleUser(),
    watchGetAllUser(),
    watchUpdateUser(),
    watchResetPassword(),
    watchNewPassword(),
    watchAddService(),
    watchGetAllService(),
    watchGetSingleService(),
    watchDeleteService(),
    watchAddBlog(),
    watchGetAllBlogs(),
    watchDeleteBlog(),
    watchUpdateService(),
    watchGetSingleBlogs(),
    watchUpdateBlog(),
    watchAddShipment(),

    watchAddOrder(),
    watchGetAllOrder(),
    watchUpdateOrder(),
    watchGetSingleOrder(),
    watchDeleteOrder(),
  ]);
}
