import React, { useState, useCallback, useMemo, useRef } from "react";
import JoditEditor from "jodit-react";
import {
  addServiceRequest,
  getAllServiceRequest,
} from "../../../redux/slice/serviceSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import toast from "react-hot-toast";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import CircularProgress from "@mui/material/CircularProgress";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
const AddServiceComp = () => {
  const navigate = useNavigate();
  const modalBodyRef = useRef(null);
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [file, setFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [bannerimgPreview, setBannerimgPreview] = useState(null);
  const [bannerimgFile, setBannerimgFile] = useState(null);
  const [content, setContent] = useState("");
  const [logs, setLogs] = useState([]);
  const [categoryName, setCategoryName] = useState("");
  const [subCategoryName, setSubCategoryName] = useState("");
  const [subCategoryPrice, setSubCategoryPrice] = useState(null);
  const [categoryToggle, setCategoryToggle] = useState(false);
  const [categoryData, setCategoryData] = useState([]);
  const [submitting, setSubmitting] = useState(false);

  const [successfullShowModal, setSuccessfullShowModal] = useState(false);
  const successMessage = useSelector((state) => state.services.successMessage);

  const successfullModalhandleClose = () => {
    setSuccessfullShowModal(false);

    dispatch(getAllServiceRequest());
    setTimeout(() => {
      navigate("/admin-pannel/all-services");
    }, 500);
  };
  const appendLog = useCallback(
    (message) => {
      const newLogs = [...logs, message];
      setLogs(newLogs);
    },
    [logs, setLogs]
  );
  const config = useMemo(
    () => ({
      readonly: false,
      height: 400,
      toolbar: true,
      spellcheck: true,
      language: "en",
      toolbarButtonSize: "medium",
      toolbarAdaptive: false,
      showCharsCounter: true,
      showWordsCounter: true,
      showXPathInStatusbar: false,
      askBeforePasteHTML: true,
      askBeforePasteFromWord: true,
      defaultActionOnPaste: "insert_clear_html",
      buttons: [
        "bold",
        "italic",
        "underline",
        "strikethrough",
        // "ul",
        // "ol",
        "indent",
        "outdent",
        "font",
        "fontsize",
        "brush",
        "paragraph",
        "align",
        "undo",
        "redo",
        "cut",
        "copy",
        "paste",
        "table",
        "link",
        "unlink",
        "hr",
        "symbol",
        "fullsize",
        "print",
        "about",
      ],
    }),
    []
  );
  const onChange = useCallback(
    (newContent) => {
      appendLog(`onChange triggered with ${newContent}`);
    },
    [appendLog]
  );
  const onBlur = useCallback(
    (newContent) => {
      appendLog(`onBlur triggered with ${newContent}`);
      setContent(newContent);
    },
    [appendLog, setContent]
  );
  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      setSubmitting(true);
      const formData = new FormData();
      formData.append("name", name);
      formData.append("cardphoto", file);
      formData.append("description", description);
      formData.append("Bannerphoto", bannerimgFile);
      formData.append("content", content);
      formData.append("categories", JSON.stringify(categoryData));

      await dispatch(addServiceRequest(formData));
      setSuccessfullShowModal(true);
    } catch (error) {
      console.error("Error:", error.message);
      setSubmitting(false);
    }
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleDiscChange = (event) => {
    setDescription(event.target.value);
  };

  const handleCategoryName = (event) => {
    setCategoryName(event.target.value);
  };

  const handleSubCategoryName = (event) => {
    setSubCategoryName(event.target.value);
  };

  const handleSubCategoryPrice = (event) => {
    setSubCategoryPrice(event.target.value);
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreview(reader.result);
    };
    reader.readAsDataURL(selectedFile);
  };

  const handleBannerImgChange = (event) => {
    const selectedFile = event.target.files[0];
    setBannerimgFile(selectedFile);
    const reader = new FileReader();
    reader.onloadend = () => {
      setBannerimgPreview(reader.result);
    };
    reader.readAsDataURL(selectedFile);
  };

  const addNewCategory = () => {
    setCategoryName("");
    if (subCategoryName !== "" && subCategoryPrice > 0) {
      const newCategory = {
        name: categoryName,
        subcategories: [],
      };
      setCategoryData((prevData) => [...prevData, newCategory]);
    }
  };
  const removeCategory = (index) => {
    setCategoryData((prevData) => {
      const newData = [...prevData];
      newData[index].subcategories = null;
      setCategoryName("");
      setSubCategoryName("");
      setSubCategoryPrice(null);
      newData.splice(index, 1);
      return newData;
    });
  };
  const removeSubCategory = (categoryIndex, subCategoryIndex) => {
    const updatedCategoryData = [...categoryData];
    const parentCategory = updatedCategoryData[categoryIndex];
    parentCategory.subcategories.splice(subCategoryIndex, 1);
    setCategoryData(updatedCategoryData);
  };

  const handleSubCatSave = () => {
    if (
      categoryName !== "" &&
      subCategoryName !== "" &&
      subCategoryPrice !== 0
    ) {
      // Check if the category already exists in the array
      const existingCategoryIndex = categoryData.findIndex(
        (cat) => cat.name === categoryName
      );

      if (existingCategoryIndex !== -1) {
        // Category already exists, update its subcategories
        const updatedCategoryData = [...categoryData];
        updatedCategoryData[existingCategoryIndex].subcategories.push({
          name: subCategoryName,
          price: subCategoryPrice,
        });
        setCategoryData(updatedCategoryData);
      } else {
        // Category doesn't exist, add a new category with the subcategory
        setCategoryData((prevData) => [
          ...prevData,
          {
            name: categoryName,
            subcategories: [
              {
                name: subCategoryName,
                price: subCategoryPrice,
              },
            ],
          },
        ]);
      }
      setSubCategoryName("");
      setSubCategoryPrice(0);
    }
  };

  return (
    <>
      <div className="addServiceContainer">
        <div className="serviceForm">
          <form onSubmit={handleSubmit} encType="multipart/form-data">
            <div className="formContainer flex flex-col items-start gap-[20px]">
              <h1 className="text-[22px]">Service Card Data</h1>

              <div className="name flex flex-col gap-[5px] w-full">
                <div className="text-[14px]">Enter name of the service </div>
                <textarea
                  type="text"
                  placeholder="Enter Name ..."
                  value={name}
                  onChange={handleNameChange}
                  className="w-full"
                />
              </div>
              <div className="cardDetails flex flex-col gap-[5px] w-full">
                <div className="text-[14px]">
                  Enter Service Description (Card/detail Pg){" "}
                </div>
                <textarea
                  type="text"
                  placeholder="Enter Description ..."
                  value={description}
                  onChange={handleDiscChange}
                  className="w-full"
                  rows={5}
                />
              </div>
              <div className="cardImg flex flex-col w-full">
                {imagePreview && (
                  <div className="selectedImg md:w-1/3">
                    <img src={imagePreview} alt="Selected" />
                  </div>
                )}
                <label htmlFor="fileInput" className="fileLabel cursor-pointer">
                  {!imagePreview && (
                    <div className="addText text-[14px]">Add Card Image</div>
                  )}
                  <div className="addImg text-[50px]">+</div>
                </label>
                <input
                  type="file"
                  id="fileInput"
                  accept=".png, .svg, .jpg, .jpeg"
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                />
              </div>

              <div className="descriptionPage">
                <h1 className="text-[22px]">Detail Page Data</h1>
                <div className="bannerImg flex flex-col w-full">
                  {bannerimgPreview && (
                    <div className="selectedImg md:w-full">
                      <img src={bannerimgPreview} alt="Selected" />
                    </div>
                  )}
                  <label
                    htmlFor="bannerfileInput"
                    className="fileLabel cursor-pointer"
                  >
                    {!bannerimgPreview && (
                      <div className="addText text-[14px]">
                        Add Banner Image
                      </div>
                    )}
                    <div className="addImg text-[50px]">+</div>
                  </label>
                  <input
                    type="file"
                    id="bannerfileInput"
                    accept=".png, .svg, .jpg, .jpeg"
                    onChange={handleBannerImgChange}
                    style={{ display: "none" }}
                  />
                </div>
                <div className="serviceDetails flex flex-col gap-[5px]">
                  <div>Add Service Details</div>
                  <JoditEditor
                    value={content}
                    config={config}
                    tabIndex={1}
                    onBlur={onBlur}
                    onChange={onChange}
                  />
                </div>
                <div className="serviceCategories py-5">
                  <h1 className="text-[22px]">
                    Service Categories With Sub Categories and Prices
                  </h1>
                  <div className="category">
                    <div
                      className="text-[18px] font-extrabold cursor-pointer py-5"
                      onClick={() => {
                        setCategoryToggle(true);
                      }}
                    >
                      + Add Category
                    </div>
                    {categoryToggle && (
                      <div className="categoryName flex flex-col gap-[5px] w-[33%]">
                        <div className="text-[14px]">Enter Category Name </div>
                        <input
                          type="text"
                          placeholder="Enter Category Name ..."
                          value={categoryName}
                          onChange={handleCategoryName}
                        />
                      </div>
                    )}
                    {categoryName !== "" && (
                      <div className="subCategories py-2 flex flex-col gap-[5px]">
                        <div>Enter Sub category and pricing</div>
                        <div className="pricingSection flex items-center gap-10">
                          <input
                            type="text"
                            placeholder="Sub Category Name ..."
                            value={subCategoryName}
                            onChange={handleSubCategoryName}
                          />
                          <div className="price">
                            <input
                              type="number"
                              placeholder="Price ..."
                              value={subCategoryPrice}
                              onChange={handleSubCategoryPrice}
                            />
                          </div>
                        </div>
                        <div
                          className="saveSubCat cursor-pointer bg-primary text-white px-3 py-1 w-[100px] text-center rounded"
                          onClick={handleSubCatSave}
                        >
                          Save
                        </div>
                        <div
                          className="saveSubCat cursor-pointer bg-primary text-white px-3 py-3 w-[170px] text-center rounded"
                          onClick={addNewCategory}
                        >
                          Add New category
                        </div>
                      </div>
                    )}
                    <div className="categoryTable flex w-[50%]">
                      {console.log("cat data", categoryData)}
                      <div className="w-[100%] py-2 flex flex-col gap-y-4">
                        {categoryData.length > 0 &&
                          categoryData.map(
                            (category, categoryIndex) =>
                              category.subcategories?.length > 0 && (
                                <div
                                  key={categoryIndex}
                                  className="py-5 border rounded flex flex-col gap-5"
                                >
                                  <div className="px-5 text-[20px] font-semibold	flex items-center gap-10">
                                    {category?.name}
                                    <svg
                                      onClick={() =>
                                        removeCategory(categoryIndex)
                                      }
                                      xmlns="http://www.w3.org/2000/svg"
                                      width={15}
                                      height={15}
                                      fill="currentColor"
                                      className="bi bi-trash3 cursor-pointer text-black/[0.5] hover:text-black text-[16px] md:text-[20px]"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                                    </svg>
                                  </div>
                                  {category.subcategories.map(
                                    (subCategoryData, subCategoryIndex) => (
                                      <div className="flex items-center justify-between px-5">
                                        <div className="font-semibold	text-[14px]">
                                          {subCategoryData?.name}
                                        </div>
                                        <div className="text-[14px] flex items-center gap-3">
                                          {subCategoryData?.price}
                                          <svg
                                            onClick={() =>
                                              removeSubCategory(
                                                categoryIndex,
                                                subCategoryIndex
                                              )
                                            }
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={16}
                                            height={16}
                                            fill="currentColor"
                                            className="bi bi-trash3 cursor-pointer text-black/[0.5] hover:text-black text-[16px] md:text-[20px]"
                                            viewBox="0 0 16 16"
                                          >
                                            <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                                          </svg>
                                        </div>
                                      </div>
                                    )
                                  )}
                                </div>
                              )
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="submitService pt-3">
              <div
                className="submitBtn cursor-pointer bg-primary text-white px-5 py-2 w-[150px] text-center rounded"
                onClick={handleSubmit}
              >
                {submitting ? "Submiting ..." : "Submit"}
              </div>
            </div>
          </form>
        </div>
      </div>

      <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={successfullShowModal}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          <p className="font-bold "> Upload New Service</p>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={successfullModalhandleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          {successMessage ? (
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ minWidth: 400, minHeight: 70 }}
            >
              <h1 className="text-primary text-center mt-5 text-2xl font-bold">
                Service Added successfully!
              </h1>
            </div>
          ) : (
            <div
              className="flex-col "
              style={{ minWidth: 400, minHeight: 100 }}
            >
              <h6 className="text-2xl    text-center mt-4">
                uploading Service Data
              </h6>
              <p className="text-center my-3">
                {/* <Stack sx={{ color: "grey.500" }} spacing={2} direction="row"> */}
                <CircularProgress color="success" />
              </p>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="success"
            autoFocus
            onClick={successfullModalhandleClose}
          >
            Ok
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
};

export default AddServiceComp;
