import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { updateUserRequest } from "../../../redux/slice/userSlice";
import { useDispatch } from "react-redux";

export default function UpdateProfile() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [firstname, SetFirstName] = useState("");
  const [lastname, SetLastName] = useState("");
  const [email, SetEmail] = useState("");
  const [phone, SetPhone] = useState("");
  const [city, SetCity] = useState("");
  const [address, SetAddress] = useState("");
  const [postcode, SetPostcode] = useState("");

  const userdata = JSON.parse(localStorage.getItem("user"));
  console.log("first name is :", userdata);
  useEffect(() => {
    SetFirstName(userdata?.user?.firstname);
    SetLastName(userdata?.user?.lastname);
    SetEmail(userdata?.user?.email);
    SetPhone(userdata?.user?.phone);
    SetAddress(userdata?.user?.address);
    SetCity(userdata?.user?.city);
    SetPostcode(userdata?.user?.postalcode);
  }, []);

  const HandleSubmit = (e) => {
    console.log("form clicked");
    e.preventDefault();

    const data = {
      firstname,
      lastname,
      email,
      phone,
      city,
      address,
    };

    dispatch(updateUserRequest({ data: data, id: userdata.user.id }));
  };

  return (
    <main className="  flex justify-center">
      <div className="  w-[70%] mt-7 bg-white border border-gray-200 rounded-xl shadow-sm dark:bg-gray-800 dark:border-gray-700">
        <div className="p-4 sm:p-7">
          <div className="text-center">
            <h1 className="block text-2xl font-bold text-secondary">
              Edit Your Profile
            </h1>
          </div>
          <div className="mt-5">
            {/* Form */}

            <form onSubmit={HandleSubmit}>
              <div className="grid grid-cols-2 gap-4">
                {/* Form Group */}
                <div>
                  <label
                    htmlFor="firstname"
                    className="block text-sm mb-2 text-secondary"
                  >
                    First Name
                  </label>
                  <div className="relative">
                    <input
                      type="text"
                      value={firstname}
                      onChange={(e) => SetFirstName(e.target.value)}
                      className="hl focus:outline-none py-3 px-4 block w-full border-[#ccc] rounded-0 text-sm text-content border-b"
                      required
                    />
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="lastname"
                    className="block text-sm mb-2 text-secondary"
                  >
                    Last Name
                  </label>
                  <div className="relative">
                    <input
                      type="text"
                      value={lastname}
                      onChange={(e) => SetLastName(e.target.value)}
                      className="hl focus:outline-none py-3 px-4 block w-full border-[#ccc] rounded-0 text-sm text-content border-b"
                    />
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm mb-2 text-secondary"
                  >
                    Email address
                  </label>
                  <div className="relative">
                    <input
                      type="email"
                      value={email}
                      onChange={(e) => SetEmail(e.target.value)}
                      className="hl focus:outline-none py-3 px-4 block w-full border-[#ccc] rounded-0 text-sm text-content border-b"
                    />
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="phone"
                    className="block text-sm mb-2 text-secondary"
                  >
                    Phone number
                  </label>
                  <div className="relative">
                    <input
                      type="number"
                      value={phone}
                      onChange={(e) => SetPhone(e.target.value)}
                      className="hl focus:outline-none py-3 px-4 block w-full border-[#ccc] rounded-0 text-sm text-content border-b"
                    />
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="city"
                    className="block text-sm mb-2 text-secondary"
                  >
                    City
                  </label>
                  <div className="relative">
                    <input
                      type="text"
                      value={city}
                      onChange={(e) => SetCity(e.target.value)}
                      className="hl focus:outline-none py-3 px-4 block w-full border-[#ccc] rounded-0 text-sm text-content border-b"
                    />
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="address"
                    className="block text-sm mb-2 text-secondary"
                  >
                    Address
                  </label>
                  <div className="relative">
                    <input
                      type="text"
                      value={address}
                      onChange={(e) => SetAddress(e.target.value)}
                      className="hl focus:outline-none py-3 px-4 block w-full border-[#ccc] rounded-0 text-sm text-content border-b"
                    />
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="postcode"
                    className="block text-sm mb-2 text-secondary"
                  >
                    Postal Code
                  </label>
                  <div className="relative">
                    <input
                      type="text"
                      value={postcode}
                      onChange={(e) => SetAddress(e.target.value)}
                      className="hl focus:outline-none py-3 px-4 block w-full border-[#ccc] rounded-0 text-sm text-content border-b"
                    />
                  </div>
                </div>
              </div>
              <div className="ml-atuo">
                <button
                  type="submit"
                  className=" mt-10 py-3 px-4 flex justify-end  gap-2 rounded-md border border-transparent font-semibold bg-primary text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800 "
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </main>
  );
}
