import { createSlice } from "@reduxjs/toolkit";
const ShipmentSlice = createSlice({
  name: "Shipment",
  initialState: {
    loading: false,
    error: null,
    shipmentValues: null,
  },
  reducers: {
    addShipmentRequest: (state) => {
      state.loading = true;
      state.error = null;
    },
    addShipmentSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.shipmentValues = action.payload;
    },
    addShipmentFailure: (state, action) => {
      state.loading = true;
      state.error = action.payload;
    },
  },
});
export const { addShipmentRequest, addShipmentSuccess, addShipmentFailure } =
  ShipmentSlice.actions;
export default ShipmentSlice.reducer;
