import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import slugify from "slugify";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllServiceRequest,
  getSingleServiceRequest,
} from "../redux/slice/serviceSlice";
import { Buffer } from "buffer";
import { CircularProgress } from "@mui/material";

export default function ServicesGrid() {
  const servicesArray = useSelector(
    (state) => state.services.services?.services
  );

  return (
    <>
      {servicesArray ? (
        servicesArray.map((item) => (
          <div
            className="serviceGrid md:w-1/3  m-6 text-center mx-auto px-6 flex flex-col justify-between "
            key={item.id}
          >
            <div className=" h-64 overflow-hidden">
              <Link
                to={`/service/${item.name
                  .replace(/&/g, "&")
                  .replace(/\s+/g, "-")}`}
                state={{ item }}
              >
                <img
                  className="object-cover w-full object-center h-full  mx-auto hover:opacity-70"
                  src={
                    item?.cardphoto
                      ? `data:${
                          item?.cardphoto?.contentType
                        };base64,${Buffer.from(item?.cardphoto?.data).toString(
                          "base64"
                        )}`
                      : "" // Set an empty string if data is not available
                  }
                  alt={item.name}
                />
              </Link>
            </div>
            <div className="bg-[#f9f9f9] h-72   flex flex-col justify-between">
              <div className="md:p-6 flex flex-col">
                <Link
                  to={`/service/${item.name
                    .replace(/&/g, "&")
                    .replace(/\s+/g, "-")}`}
                  state={{ item }}
                  className="h1 text-3xl text-secondary mb-4 hover:text-primary mt-5 md:mt-2"
                >
                  {item?.name}
                </Link>
                <p className="text-base text-content mb-4">
                  {item?.description.split(" ").slice(0, 20).join(" ")}
                  {item?.description.split(" ").length > 20 ? "..." : ""}
                </p>
              </div>
              <div className=" flex justify-end items-center my-auto mb-5">
                <Link
                  to={`/service/${item.name
                    .replace(/&/g, "&")
                    .replace(/\s+/g, "-")}`}
                  state={{ item }}
                  className="bg-content hover:bg-primary text-white py-2 px-5 text-sm flex flex-row rounded-md mr-5 self-end"
                >
                  Learn Mores
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-5 h-5 ml-3 "
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
                    />
                  </svg>
                </Link>
              </div>
            </div>
          </div>
        ))
      ) : (
        <>
          <div className="mx-auto flex my-5 ">
            <h6 className="text-4xl font-bold mr-3 ">Loading Services</h6>

            <CircularProgress color="success" />
          </div>
        </>
      )}
    </>
  );
}
