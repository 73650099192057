import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { addOrderRequest } from "../../redux/slice/orderSlice";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import CircularProgress from "@mui/material/CircularProgress";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function CheckOutPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [successfullShowModal, setSuccessfullShowModal] = useState(false);
  const successMessage = useSelector((state) => state.orders.successMessage);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [cellphone, setCellphone] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [postcode, setPostcode] = useState("");

  const [note, setNote] = useState("");
  const [cartItems, setCartItems] = useState([]);

  const [emailError, setEmailError] = useState("");

  const [tax, setTax] = useState("");
  const [subtotal, setSubTotal] = useState("");

  const userdata = JSON.parse(localStorage.getItem("user"));

  const currntUserId = userdata?.user?.id;

  const itemStrings = cartItems.map(
    (item) => `${item.quantity} x ${item.name}`
  );
  const totalPrice = cartItems.reduce(
    (accumulator, item) => accumulator + item.quantity * item.price,
    0
  );

  const successfullModalhandleClose = () => {
    setSuccessfullShowModal(false);
    localStorage.removeItem("cart");
    setTimeout(() => {
      navigate("/");
    }, 500);
  };

  useEffect(() => {
    if (totalPrice) {
      const tax = (totalPrice * 15) / 100;
      const roundedTax = parseFloat(tax.toFixed(2)); // Round tax to 2 decimal places
      setTax(roundedTax);

      const subtotal = roundedTax + totalPrice;
      const roundedSubtotal = parseFloat(subtotal.toFixed(2)); // Round subtotal to 2 decimal places
      setSubTotal(roundedSubtotal);
    }
    // Retrieve cart items from local storage
    const savedCartItems = JSON.parse(localStorage.getItem("cart"));
    if (savedCartItems) {
      setCartItems(savedCartItems);
    }
  }, [totalPrice]);

  const itemString = itemStrings.join(", ");

  const handleForm = async (event) => {
    debugger;
    event.preventDefault();
    if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError("Please enter a valid email address.");
      return;
    }

    // validate confirm email

    const orderData = {
      name,
      email,
      phone,
      cellphone,
      address,
      items: cartItems,
      amount: totalPrice,
      city,
      postcode,
      note,
      status: "pending",
    };

    dispatch(addOrderRequest({ ...orderData, userId: currntUserId }));
    setSuccessfullShowModal(true);
  };

  return (
    <>
      <section>
        <div className=" text-center mt-16">
          <h6 className="text-4xl text-primary color-primary">
            YOUR PAYMENT HAVE BEEN SUCCESSFULL
          </h6>
          <h4 className="text-2xl text-secondary">
            Now you can Place an your Order
          </h4>
        </div>
      </section>
      <section className="py-5">
        <div className="container mx-auto px-5 md:px-0">
          <form onSubmit={handleForm}>
            <div className="flex flex-col md:flex-row">
              <div className="md:basis-8/12">
                <div className="grid gap-4 lg:gap-6 py-10 md:px-10">
                  <p className="py-5 text-content">
                    Please fill out the information below.
                  </p>

                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 lg:gap-6">
                    <div>
                      <input
                        type="text"
                        name="name"
                        id="name"
                        placeholder="Name"
                        className="hl focus:outline-none py-3 px-4 block w-full border-[#ccc] rounded-0 text-sm text-content border-b "
                        value={name}
                        onChange={(event) => setName(event.target.value)}
                        required
                      />
                    </div>
                    <div>
                      <input
                        type="email"
                        name="email"
                        id="email"
                        autoComplete="email"
                        className="hl focus:outline-none py-3 px-4 block w-full border-[#ccc] rounded-0 text-sm text-content border-b "
                        placeholder="Email"
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                        required
                      />
                      {emailError && <div>{emailError}</div>}
                    </div>
                  </div>

                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 lg:gap-6">
                    <div>
                      <input
                        type="tel"
                        name="phone-number"
                        id="phone-number"
                        className="hl focus:outline-none py-3 px-4 block w-full border-[#ccc] rounded-0 text-sm text-content border-b "
                        placeholder="Phone Number"
                        value={phone}
                        onChange={(event) => setPhone(event.target.value)}
                        required
                      />
                    </div>
                    <div>
                      <input
                        type="tel"
                        name="cell-phone"
                        id="cell-phone"
                        autoComplete="phone"
                        className="hl focus:outline-none py-3 px-4 block w-full border-[#ccc] rounded-0 text-sm text-content border-b "
                        placeholder="Cell Phone Number"
                        value={cellphone}
                        onChange={(event) => setCellphone(event.target.value)}
                      />
                    </div>
                  </div>

                  <p className="py-5 text-content">
                    Shipping or Dlivery Details{" "}
                  </p>
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 lg:gap-6">
                    <div>
                      <input
                        type="text"
                        name="address"
                        id="address"
                        autoComplete="address"
                        className="hl focus:outline-none py-3 px-4 block w-full border-[#ccc] rounded-0 text-sm text-content border-b "
                        placeholder="Adress"
                        value={address}
                        onChange={(event) => setAddress(event.target.value)}
                        required
                      />
                    </div>
                    <div>
                      <input
                        type="text"
                        name="city"
                        id="city"
                        className="hl focus:outline-none py-3 px-4 block w-full border-[#ccc] rounded-0 text-sm text-content border-b "
                        placeholder="City"
                        value={city}
                        onChange={(event) => setCity(event.target.value)}
                        required
                      />
                    </div>
                  </div>

                  <div>
                    <input
                      type="text"
                      name="postcode"
                      id="postcode"
                      className="hl focus:outline-none py-3 px-4 block w-full border-[#ccc] rounded-0 text-sm text-content border-b "
                      placeholder="PostCode"
                      value={postcode}
                      onChange={(event) => setPostcode(event.target.value)}
                      required
                    />
                  </div>

                  <p className="py-5 text-content">Notes For order </p>
                  <div>
                    <textarea
                      id="note"
                      name="note"
                      rows={4}
                      className="hl  focus:outline-none py-3 px-4 block w-full border-[#ccc] rounded-0 text-sm text-content border-b "
                      placeholder="Order Note"
                      value={note}
                      onChange={(event) => setNote(event.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="md:basis-4/12 py-10 relative">
                <div className="md:sticky md:top-[230px]">
                  <div className="p-5 my-5 bg-secondary/[0.05] rounded-xl">
                    {/* cart items summary  */}
                    {cartItems.length > 0 && (
                      <div>
                        <h1 className="py-3 h1 text-secondary text-xl">
                          Cart Items
                        </h1>
                        <table className="w-full  text-center">
                          <thead className="bg-content/[0.08]">
                            <tr>
                              <th className="p-3 h1">Item</th>
                              <th className="p-3 h1">Quantity</th>
                              {/* <th className="p-3 h1">Price</th> */}
                              <th className="p-3 h1">Subtotal</th>
                            </tr>
                          </thead>
                          <tbody>
                            {cartItems.map((item) => (
                              <tr key={item.id}>
                                <td className="p-3 p">{item.name}</td>
                                <td className="p-3 p">{item.quantity}</td>
                                {/* <td className="p-3 p">{item.price}</td> */}
                                <td className="p-3 p">
                                  {item.quantity * item.price}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    )}
                    <div className="flex justify-between pt-5">
                      <div className="uppercase text-lg md:text-xl h1 text-secondary">
                        Vat Tax 15%
                      </div>
                      <div className="text-md md:text-lg font-medium text-black">
                        R{tax}
                      </div>
                    </div>{" "}
                    <div className="flex justify-between pt-1">
                      <div className="uppercase text-lg md:text-xl h1 text-secondary">
                        Total
                      </div>
                      <div className="text-md md:text-lg font-medium text-black">
                        R{subtotal}
                      </div>
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="w-full py-4 rounded-md bg-primary  text-white text-lg font-medium transition-transform active:scale-95 mb-3 hover:opacity-75 flex items-center gap-2 justify-center"
                  >
                    Place A Order
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>

        <BootstrapDialog
          aria-labelledby="customized-dialog-title"
          open={successfullShowModal}
        >
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            <p className="font-bold "> Add New Order</p>
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={successfullModalhandleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers>
            {successMessage ? (
              <div
                className="d-flex align-items-center justify-content-center"
                style={{ minWidth: 400, minHeight: 70 }}
              >
                <h1 className="text-primary text-center mt-5 text-2xl font-bold">
                  Order Added successfully!
                </h1>
              </div>
            ) : (
              <div
                className="flex-col "
                style={{ minWidth: 400, minHeight: 100 }}
              >
                <h6 className="text-2xl    text-center mt-4">
                  Adding New Order
                </h6>
                <p className="text-center my-3">
                  {/* <Stack sx={{ color: "grey.500" }} spacing={2} direction="row"> */}
                  <CircularProgress color="success" />
                </p>
              </div>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="success"
              autoFocus
              onClick={successfullModalhandleClose}
            >
              Ok
            </Button>
          </DialogActions>
        </BootstrapDialog>
      </section>
    </>
  );
}
