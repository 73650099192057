import { createSlice } from "@reduxjs/toolkit";

const BlogSlice = createSlice({
  name: "Blog",
  initialState: {
    Blogs: [],
    blog: null,
    loading: false,
    error: null,
    successMessage: false,
  },
  reducers: {
    addBlogRequest: (state) => {
      state.loading = true;
      state.error = null;
      state.successMessage = false;
    },
    addBlogSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.successMessage = true;
      state.blog = action.payload;
    },
    addBlogFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.successMessage = false;
    },
    getAllBlogsRequest: (state) => {
      state.loading = true;
      state.error = null;
    },
    getAllBlogsSuccess: (state, action) => {
      state.loading = false;
      state.Blogs = action.payload;
      state.error = null;
    },
    getAllBlogsFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    getSingleBlogsRequest: (state) => {
      state.loading = true;
      state.error = null;
    },
    getSingleBlogsSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.success = true;
      state.blog = action.payload;
    },
    getSingleBlogsFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    updateBlogsRequest: (state) => {
      state.loading = true;
      state.error = null;
      state.successMessage = false;
    },
    updateBlogsSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.blog = action.payload;
      state.successMessage = true;
    },
    updateBlogsFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    deleteBlogsRequest: (state) => {
      state.loading = true;
      state.error = null;
      state.successMessage = false;
    },
    deleteBlogsSuccess: (state) => {
      state.loading = false;
      state.error = null;
      state.successMessage = true;
    },
    deleteBlogsFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.successMessage = false;
    },
  },
});
export const {
  addBlogRequest,
  addBlogSuccess,
  addBlogFailure,
  getAllBlogsRequest,
  getAllBlogsSuccess,
  getAllBlogsFailure,
  getSingleBlogsRequest,
  getSingleBlogsSuccess,
  getSingleBlogsFailure,
  updateBlogsRequest,
  updateBlogsSuccess,
  updateBlogsFailure,
  deleteBlogsRequest,
  deleteBlogsSuccess,
  deleteBlogsFailure,
} = BlogSlice.actions;
export default BlogSlice.reducer;
