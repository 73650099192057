import React from "react";
import Profile from "../../../components/AdminPannelComp/Profile/Profile";

const AdminProfile = () => {
  return (
    <>
      <Profile />
    </>
  );
};

export default AdminProfile;
