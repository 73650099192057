import { createSlice } from "@reduxjs/toolkit";

const serviceSlice = createSlice({
  name: "service",
  initialState: {
    services: [],
    service: null,
    loading: false,
    error: null,
    successMessage: false,
  },
  reducers: {
    addServiceRequest: (state) => {
      state.loading = true;
      state.error = null;
      state.successMessage = false;
    },
    addServiceSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.service = action.payload;
      state.successMessage = true;
    },
    addServiceFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.success = false;
      state.successMessage = false;
    },
    getAllServiceRequest: (state) => {
      state.loading = true;
      state.error = null;
    },
    getAllServiceSuccess: (state, action) => {
      state.loading = false;
      state.services = action.payload;
      state.error = null;
    },
    getAllServiceFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    updateServiceRequest: (state) => {
      state.loading = true;
      state.error = null;
      state.success = false;
      state.successMessage = false;
    },
    updateServiceSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.success = true;
      state.service = action.payload;
      state.successMessage = true;
    },
    updateServiceFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    getSingleServiceRequest: (state) => {
      state.loading = true;
      state.error = null;
    },
    getSingleServiceSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.success = true;
      state.service = action.payload;
    },
    getSingleServiceFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    deleteServiceRequest: (state) => {
      state.loading = true;
      state.error = null;
      state.successMessage = false;
    },
    deleteServiceSuccess: (state) => {
      state.loading = false;
      state.error = null;
      state.successMessage = true;
    },
    deleteServiceFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.successMessage = false;
    },
  },
});
export const {
  addServiceRequest,
  addServiceSuccess,
  addServiceFailure,
  getAllServiceRequest,
  getAllServiceSuccess,
  getAllServiceFailure,
  getSingleServiceRequest,
  getSingleServiceSuccess,
  getSingleServiceFailure,

  updateServiceRequest,
  updateServiceSuccess,
  updateServiceFailure,

  deleteServiceRequest,
  deleteServiceSuccess,
  deleteServiceFailure,
} = serviceSlice.actions;
export default serviceSlice.reducer;
