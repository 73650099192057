import React, { useState } from "react";
import { Link } from "react-router-dom";
export default function Address({ addressDataValues }) {
  const [collectionAddressData, setCollectionAddressData] = useState({
    collectionStreetAddress: "",
    collectionLocalArea: "",
    collectionCity: "",
    collectionPostCode: "",
    collectionMobile: "",
    collectionEmail: "",
    collectionZone: "",
    collectionName: "",
    collectionInstructions: "",
  });
  const [deliveryAddressData, setDeliveryAddressData] = useState({
    deliveryStreetAddress: "",
    deliveryLocalArea: "",
    deliveryCity: "",
    deliveryPostCode: "",
    deliveryMobile: "",
    deliveryEmail: "",
    deliveryZone: "",
    deliveryName: "",
    deliveryInstructions: "",
    // deliveryStreetAddress: addressData.collectionStreetAddress,
    // deliveryLocalArea: addressData.collectionLocalArea,
    // deliveryCity: addressData.collectionCity,
    // deliveryPostCode: addressData.collectionPostCode,
    // deliveryMobile: addressData.collectionMobile,
    // deliveryEmail: addressData.collectionEmail,
    // deliveryZone: addressData.collectionZone,
    // deliveryName: addressData.collectionName,
    // deliveryInstructions: addressData.collectionInstructions,
  });
  const [isDeliverySection, setIsDeliverySection] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setCollectionAddressData({
      ...collectionAddressData,
      [name]: value,
    });
  };
  const handleDeliveryChange = (event) => {
    const { name, value } = event.target;
    setDeliveryAddressData({
      ...deliveryAddressData,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      console.log("Collection Address", collectionAddressData);
      console.log("Delivery Address", deliveryAddressData);
      addressDataValues(collectionAddressData, deliveryAddressData);
    } catch (error) {
      console.log("Error", error);
    }
  };
  // const showDeliverySection = () => {
  //   setIsDeliverySection(!isDeliverySection);
  // };
  return (
    <div className="container">
      {deliveryAddressData.deliveryStreetAddress}
      <form className="gap-5 w-full" onSubmit={handleSubmit}>
        <div className="bg-white shadow-lg rounded-md  mx-auto px-8 py-6 my-10  w-full">
          <h3 className="text-xl font-semibold">Collection address</h3>
          <div className="w-full  grid grid-cols-2 gap-5 ">
            <div className="mb-4">
              <input
                type="text"
                id="collectionName"
                className="w-full border-b border-0 border-content outline-none  text-sm focus:ring-0 focus:ring-offset-0 focus:shadow-none focus:border-b focus:border-b-content"
                placeholder="Name"
                value={collectionAddressData.collectionName}
                name="collectionName"
                onChange={handleChange}
              />
            </div>
            <div className="mb-4">
              <input
                type="text"
                id="collectionStreetAddress"
                className="w-full border-b border-0 border-content outline-none  text-sm focus:ring-0 focus:ring-offset-0 focus:shadow-none focus:border-b focus:border-b-content"
                placeholder="Street Address"
                value={collectionAddressData.streetAddress}
                name="collectionStreetAddress"
                onChange={handleChange}
              />
            </div>
            <div className="mb-4">
              <input
                type="text"
                id="collectionLocalArea"
                className="w-full border-b border-0 border-content outline-none  text-sm focus:ring-0 focus:ring-offset-0 focus:shadow-none focus:border-b focus:border-b-content"
                placeholder="Local Area"
                value={collectionAddressData.localArea}
                name="collectionLocalArea"
                onChange={handleChange}
              />
            </div>
            <div className="mb-4">
              <input
                type="text"
                id="collectionCity"
                className="w-full border-b border-0 border-content outline-none  text-sm focus:ring-0 focus:ring-offset-0 focus:shadow-none focus:border-b focus:border-b-content"
                placeholder="City"
                value={collectionAddressData.collectionCity}
                name="collectionCity"
                onChange={handleChange}
              />
            </div>
            <div className="mb-4">
              <input
                type="text"
                id="collectionPostCode"
                className="w-full border-b border-0 border-content outline-none  text-sm focus:ring-0 focus:ring-offset-0 focus:shadow-none focus:border-b focus:border-b-content"
                placeholder="Postcode"
                value={collectionAddressData.collectionPostCode}
                name="collectionPostCode"
                onChange={handleChange}
              />
            </div>
            <div className="mb-4">
              <input
                type="text"
                id="collectionZone"
                className="w-full border-b border-0 border-content outline-none  text-sm focus:ring-0 focus:ring-offset-0 focus:shadow-none focus:border-b focus:border-b-content"
                placeholder="Zone"
                value={collectionAddressData.collectionZone}
                name="collectionZone"
                onChange={handleChange}
              />
            </div>

            <div className="mb-4">
              <input
                type="text"
                id="collectionMobile"
                className="w-full border-b border-0 border-content outline-none  text-sm focus:ring-0 focus:ring-offset-0 focus:shadow-none focus:border-b focus:border-b-content"
                placeholder="Mobile Number"
                value={collectionAddressData.collectionMobile}
                name="collectionMobile"
                onChange={handleChange}
              />
            </div>
            <div className="mb-4">
              <input
                type="text"
                id="collectionEmail"
                className="w-full border-b border-0 border-content outline-none  text-sm focus:ring-0 focus:ring-offset-0 focus:shadow-none focus:border-b focus:border-b-content"
                placeholder="Email"
                value={collectionAddressData.collectionEmail}
                name="collectionEmail"
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div>
            <label className="text-content text-sm block pm textarea-label">
              instruction
            </label>
            <textarea
              rows={4}
              maxLength={1000}
              formcontrolname="instruction"
              className=" w-full  border-1 border-content outline-none  text-sm focus:ring-0 focus:ring-offset-0 focus:shadow-none focus:border-b focus:border-b-content"
              placeholder=""
              defaultValue={""}
              value={collectionAddressData.collectionInstructions}
              name="collectionInstructions"
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="bg-white shadow-lg rounded-md  mx-auto px-8 py-6 my-10 relative ">
          <h1 className="py-3">Delivery Instructions</h1>
          {/* <div>
            Keep the delivery data same as collection &nbsp;
            <span className="font-bold">/</span>
            &nbsp;
            <span
              className="font-bold underline cursor-pointer"
              onClick={showDeliverySection}
            >
              Change the delivery data
            </span>
          </div> */}
          {/* {isDeliverySection && ( */}
          <div className="">
            <div className="bloc-preference">
              {/* <div className="preference-wrapper">
                <span className="pb text-base">
                  You don't have any instructions yet
                </span>
              </div> */}
            </div>
            <div>
              <div className="flex justify-end items-center p-4 ">
                <a href="" className="text-primary text-base mr-7">
                  {" "}
                  Edit
                </a>
                <a className="text-end bg-[#EB5757] bg-opacity-30 rounded-full w-8 h-8 text-sm flex justify-center items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={20}
                    height={20}
                    fill="currentColor"
                    className="bi bi-trash3"
                    viewBox="0 0 16 16"
                  >
                    <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                  </svg>
                </a>
              </div>
              {/* <div className="p-4 ">
                <p className="pl">
                  <span className="pm">Instruction:</span> Lorem ipsum dolor sit
                  amet.
                </p>
                <p className="pl">
                  <span className="pm">Preferred contact:</span> Housekeeper
                </p>
                <p className="pl">
                  <span className="pm">Phone Number:</span> 21312312312
                </p>
              </div> */}
            </div>
            <div className="w-full  grid grid-cols-2 gap-5 ">
              <div className="mb-4">
                <input
                  type="text"
                  id="collectionName"
                  className="w-full border-b border-0 border-content outline-none  text-sm focus:ring-0 focus:ring-offset-0 focus:shadow-none focus:border-b focus:border-b-content"
                  placeholder="Name"
                  value={deliveryAddressData.deliveryName}
                  name="deliveryName"
                  onChange={handleDeliveryChange}
                  required
                />
              </div>
              <div className="mb-4">
                <input
                  type="text"
                  id="collectionStreetAddress"
                  className="w-full border-b border-0 border-content outline-none  text-sm focus:ring-0 focus:ring-offset-0 focus:shadow-none focus:border-b focus:border-b-content"
                  placeholder="Street Address"
                  value={deliveryAddressData.deliveryStreetAddress}
                  name="deliveryStreetAddress"
                  onChange={handleDeliveryChange}
                  required
                />
              </div>
              <div className="mb-4">
                <input
                  type="text"
                  id="collectionLocalArea"
                  className="w-full border-b border-0 border-content outline-none  text-sm focus:ring-0 focus:ring-offset-0 focus:shadow-none focus:border-b focus:border-b-content"
                  placeholder="Local Area"
                  value={deliveryAddressData.deliveryLocalArea}
                  name="deliveryLocalArea"
                  onChange={handleDeliveryChange}
                />
              </div>
              <div className="mb-4">
                <input
                  type="text"
                  id="collectionCity"
                  className="w-full border-b border-0 border-content outline-none  text-sm focus:ring-0 focus:ring-offset-0 focus:shadow-none focus:border-b focus:border-b-content"
                  placeholder="City"
                  value={deliveryAddressData.deliveryCity}
                  name="deliveryCity"
                  onChange={handleDeliveryChange}
                  required
                />
              </div>
              <div className="mb-4">
                <input
                  type="text"
                  id="collectionPostCode"
                  className="w-full border-b border-0 border-content outline-none  text-sm focus:ring-0 focus:ring-offset-0 focus:shadow-none focus:border-b focus:border-b-content"
                  placeholder="Postcode"
                  value={deliveryAddressData.deliveryPostCode}
                  name="deliveryPostCode"
                  onChange={handleDeliveryChange}
                  required
                />
              </div>
              <div className="mb-4">
                <input
                  type="text"
                  id="collectionZone"
                  className="w-full border-b border-0 border-content outline-none  text-sm focus:ring-0 focus:ring-offset-0 focus:shadow-none focus:border-b focus:border-b-content"
                  placeholder="Zone"
                  value={deliveryAddressData.deliveryZone}
                  name="deliveryZone"
                  onChange={handleDeliveryChange}
                />
              </div>

              <div className="mb-4">
                <input
                  type="text"
                  id="collectionMobile"
                  className="w-full border-b border-0 border-content outline-none  text-sm focus:ring-0 focus:ring-offset-0 focus:shadow-none focus:border-b focus:border-b-content"
                  placeholder="Mobile Number"
                  value={deliveryAddressData.deliveryMobile}
                  name="deliveryMobile"
                  onChange={handleDeliveryChange}
                  required
                />
              </div>
              <div className="mb-4">
                <input
                  type="text"
                  id="collectionEmail"
                  className="w-full border-b border-0 border-content outline-none  text-sm focus:ring-0 focus:ring-offset-0 focus:shadow-none focus:border-b focus:border-b-content"
                  placeholder="Email"
                  value={deliveryAddressData.deliveryEmail}
                  name="deliveryEmail"
                  onChange={handleDeliveryChange}
                  required
                />
              </div>
            </div>

            {/* <button className="bg-primary text-white pm py-2 px-7 rounded-md my-6">
              Add instructions
            </button> */}
            <div>
              <label className="text-content text-sm block pm textarea-label">
                Deliver Instruction
              </label>
              <textarea
                rows={4}
                maxLength={1000}
                formcontrolname="instruction"
                className=" w-full  border-1 border-content outline-none  text-sm focus:ring-0 focus:ring-offset-0 focus:shadow-none focus:border-b focus:border-b-content"
                placeholder=""
                defaultValue={""}
                value={deliveryAddressData.deliveryInstructions}
                name="deliveryInstructions"
                onChange={handleDeliveryChange}
              />
            </div>
          </div>
          <div className="flex flex-row justify-around  ">
            <button
              className="border border-[#EB5757] px-6 py-3 rounded-md"
              type="reset"
            >
              Cancel
            </button>
            <button
              className="border border-primary px-6 py-3 rounded-md"
              type="submit"
            >
              Confirm
            </button>
          </div>
          {/* )} */}
        </div>
      </form>
    </div>
  );
}
