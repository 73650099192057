import BlogCard from "../BlogCard";
import { useQuery, gql } from "@apollo/client";

export default function Blogs() {
  return (
    <>
      <section className="my-12">
        <div className="container  mx-auto">
          <div className="flex flex-wrap ">
            <BlogCard />
          </div>
        </div>
      </section>
    </>
  );
}
