import { takeLatest, put, call } from "redux-saga/effects";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "./apiUrl";
import {
  registerStart,
  registerSuccess,
  registerFailure,
  signInStart,
  signInSuccess,
  signInFailure,
  getAllUserRequest,
  getAllUserSuccess,
  getAllUserFailure,
  getSingleUserRequest,
  getSingleUserSuccess,
  getSingleUserFailure,
  updateUserRequest,
  updateUserSuccess,
  updateUserFailure,
  resetPasswordStart,
  resetPasswordSuccess,
  resetPasswordFailure,
  newPasswordStart,
  newPasswordSuccess,
  newPasswordFailure,
  forgotPasswordStart,
  forgotPasswordSuccess,
  forgotPasswordFailure,
  verifyCodeStart,
  verifyCodeSuccess,
  verifyCodeFailure,
} from "../slice/userSlice";
import axios from "axios";
import toast from "react-hot-toast";

function* registerUser(action) {
  try {
    const {
      firstname,
      lastname,
      email,
      password,
      Con_password,
      phone,
      city,
      address,
      postalcode,
    } = action.payload;
    console.log("Clicked");
    console.log("Payload value is :", action.payload);
    // Make an API request to register the user
    const response = yield call(
      axios.post,
      `${baseUrl}/api/v1/auth/register`,
      {
        firstname,
        lastname,
        email,
        password,
        Con_password,
        phone,
        city,
        address,
        postalcode,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    yield put(registerSuccess(response.data));
    if (response.data) {
      console.log(" the Response data is :", response.data);
      toast.success("The user Registered  Successfully");
      window.location.href = "/login";
    } else {
      toast.error("response", response.message);
    }
  } catch (error) {
    yield put(registerFailure(error.message));

    toast.error(error.message);
  }
}

////////////////////////////////////////////////////////////////
//*SIGIN*
////////////////////////////////////////////////////////////////

function* signInUser(action) {
  try {
    const email = action.payload.formData.email;
    const password = action.payload.formData.password;
    const rememberClient = action.payload.rememberClient;

    const response = yield call(axios.post, `${baseUrl}/api/v1/auth/login`, {
      // userNameOrEmailAddress: email,
      email: email,
      password: password,
      rememberClient: rememberClient,
    });
    yield put(signInSuccess(response.data));
    if (response.data) {
      console.log("the user data is :", response.data);

      toast.success("The user sign In Successfully");

      localStorage.setItem("user", JSON.stringify(response.data));
      // const user = JSON.parse(localStorage.getItem("user"));
      // if (user.user.role === "admin") {
      //   window.location.href = "/admin-pannel";
      // } else {
      //   const redirectPath = localStorage.getItem("redirectPath") || "/account";
      //   window.location.href = redirectPath;
      // }
    }
  } catch (error) {
    yield put(signInFailure(error.message));
    toast.error(error.message);
  }
}

//////////////////////////////////////////////////////////////////
// *ResetPassword*
//////////////////////////////////////////////////////////////////

function* resetPassword(action) {
  try {
    console.log("changepassword data is :", action.payload);
    const response = yield call(
      axios.post,
      `${baseUrl}/api/v1/auth/changepassword`,
      action.payload
    );

    yield put(resetPasswordSuccess(response.data));
    if (response.data) {
      toast.success("Reset Password Successfully");
      const user = JSON.parse(localStorage.getItem("user"));
      if (user.user.role === "admin") {
        window.location.href = "/admin-pannel/profile";
      } else {
        window.location.href = "/personal-info";
      }
    }
  } catch (error) {
    yield put(resetPasswordFailure(error.message));
  }
}
function* newPassword(action) {
  try {
    const response = yield call(
      axios.post,
      `${baseUrl}/api/v1/auth/addNewpassword`,
      action.payload
    );

    yield put(newPasswordSuccess(response.data));
    if (response.data) {
      console.log("the user data is :", response.data);

      toast.success("Added New Password Successfully");

      window.location.href = "/login";
    }
  } catch (error) {
    yield put(newPasswordFailure(error.message));
  }
}

//////////////////////////////////////////////////////////////////
// *ForgotPassword*
//////////////////////////////////////////////////////////////////

function* forgotPassword(action) {
  try {
    const email = action.payload;
    console.log("The Email is :", email);

    const response = yield call(
      axios.post,
      // "URL",
      {
        email,
      }
    );

    yield put(forgotPasswordSuccess(response.data));
    // window.location.href = "";
  } catch (error) {
    yield put(forgotPasswordFailure(error.message));
  }
}

function* getAllUserWorker() {
  try {
    const response = yield call(
      axios.get,
      `${baseUrl}/api/v1/auth/getallusers`
    );
    yield put(getAllUserSuccess(response.data));
  } catch (error) {
    yield put(getAllUserFailure(error.message));
  }
}
function* getSingleUserWorker(action) {
  try {
    const id = action.payload;
    const response = yield call(
      axios.get,
      `${baseUrl}/api/v1/auth/getsingleuser/${id}`
    );
    yield put(getSingleUserSuccess(response.data));
  } catch (error) {
    yield put(getSingleUserFailure(error.message));
  }
}

////////////////////////////////////////////////////////////
function* updateUser(action) {
  try {
    const { data, id } = action.payload;
    console.log("Update service", action.payload);
    console.log("id :", id);
    console.log("formdata :", data);
    const response = yield call(
      axios.put,
      `${baseUrl}/api/v1/auth/updateUserProfile/${id}`,
      data
    );
    yield put(updateUserSuccess(response.data));
    if (response.data) {
      localStorage.removeItem("user");
      localStorage.setItem("user", JSON.stringify(response.data));
    }
  } catch (error) {
    put(updateUserFailure(error.message));
  }
}
////////////////////////////////////////////////////////////////////

function* watchRegisterUser() {
  yield takeLatest(registerStart.type, registerUser);
}
function* watchSignInUser() {
  yield takeLatest(signInStart.type, signInUser);
}
function* watchGetAllUser() {
  yield takeLatest(getAllUserRequest.type, getAllUserWorker);
}
function* watchGetSingleUser() {
  yield takeLatest(getSingleUserRequest.type, getSingleUserWorker);
}
function* watchUpdateUser() {
  yield takeLatest(updateUserRequest.type, updateUser);
}
function* watchResetPassword() {
  yield takeLatest(resetPasswordStart.type, resetPassword);
}
function* watchNewPassword() {
  yield takeLatest(newPasswordStart.type, newPassword);
}
function* watchforgotPassword() {
  yield takeLatest(forgotPasswordStart.type, forgotPassword);
}

export {
  watchRegisterUser,
  watchSignInUser,
  watchGetSingleUser,
  watchGetAllUser,
  watchforgotPassword,
  watchResetPassword,
  watchNewPassword,
  watchUpdateUser,
};
