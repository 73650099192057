import BlogCard from "../../components/BlogCard";
import PagesHeader from "../../components/PagesHeader";
import BG from "../../assets/images/blog_1800x.png";
import { useQuery, gql } from "@apollo/client";

export default function Blog() {
  return (
    <>
      <PagesHeader
        imageUrl={BG}
        Heading={`Cleaning, Styling and \n News from Supreme Cleaners`}
        SubHeading="Our Blog"
      />

      <section className="my-12">
        <div className="container  mx-auto">
          <div className="flex flex-wrap -m-4">
            <BlogCard />
          </div>
        </div>
      </section>
    </>
  );
}
