import { configureStore } from "@reduxjs/toolkit";

import UserReducer from "./slice/userSlice";
import BlogReducer from "./slice/blogSlice";
import ServiceReducer from "./slice/serviceSlice";
import OrderReducer from "./slice/orderSlice";

import createSagaMiddleware from "redux-saga";
import rootSaga from "./Saga/rootSaga";

const sagaMiddleware = createSagaMiddleware();
const store = configureStore({
  reducer: {
    user: UserReducer,
    blogs: BlogReducer,
    services: ServiceReducer,
    orders: OrderReducer,
  },
  middleware: () => [sagaMiddleware],
});

sagaMiddleware.run(rootSaga);

export default store;
// middleware: (getDefaultMiddleware) =>
// getDefaultMiddleware().concat(sagaMiddleware),
