import { takeLatest, put, call } from "redux-saga/effects";
import axios from "axios";
import toast from "react-hot-toast";
import { baseUrl } from "./apiUrl";

import {
  addBlogRequest,
  addBlogSuccess,
  addBlogFailure,
  getAllBlogsRequest,
  getAllBlogsSuccess,
  getAllBlogsFailure,
  getSingleBlogsRequest,
  getSingleBlogsSuccess,
  getSingleBlogsFailure,
  updateBlogsRequest,
  updateBlogsSuccess,
  updateBlogsFailure,
  deleteBlogsRequest,
  deleteBlogsSuccess,
  deleteBlogsFailure,
} from "../slice/blogSlice";
console.log("the url is :", baseUrl);
function* addBlog(action) {
  try {
    console.log("Clicked");
    const { formData } = action.payload;

    // Make the API call with axios
    const response = yield call(
      axios.post,
      `${baseUrl}/api/v1/blog/addblog`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    yield put(addBlogSuccess(response.data));
  } catch (error) {
    yield put(addBlogFailure(error.message));
  }
}
function* getAllBlogsWorker() {
  try {
    console.log("Saga Clicked");
    const response = yield call(axios.get, `${baseUrl}/api/v1/blog/getAllBlog`);
    yield put(getAllBlogsSuccess(response.data));
    // console.log("All Blogs are :", response.data);
  } catch (error) {
    yield put(getAllBlogsFailure(error.message));
  }
}
function* getSingleBlogsWorker(action) {
  try {
    console.log("Single  Clicked Service");
    const id = action.payload;
    const response = yield call(
      axios.get,
      `${baseUrl}/api/v1/blog/getSingleBlog/${id}`
    );
    yield put(getSingleBlogsSuccess(response.data));
    console.log(" Single Service is  :", response.data);
  } catch (error) {
    yield put(getSingleBlogsFailure(error.message));
  }
}
function* updateBlog(action) {
  try {
    const { formdata, id } = action.payload;
    console.log("Update Blog", action.payload);
    console.log("id :", id);
    console.log("formdata :", formdata);
    const response = yield call(
      axios.put,
      `${baseUrl}/api/v1/blog/updateBlog/${id}`,
      formdata
    );
    yield put(updateBlogsSuccess(response.data));
  } catch (error) {
    put(updateBlogsFailure(error.message));
  }
}
function* deleteBlogWorker(action) {
  try {
    const id = action.payload;
    yield call(axios.delete, `${baseUrl}/api/v1/blog/deleteBlog/${id}`);
    yield put(deleteBlogsSuccess());
  } catch (error) {
    yield put(deleteBlogsFailure(error.message));
  }
}

function* watchAddBlog() {
  yield takeLatest(addBlogRequest.type, addBlog);
}
function* watchGetAllBlogs() {
  yield takeLatest(getAllBlogsRequest.type, getAllBlogsWorker);
}
function* watchGetSingleBlogs() {
  yield takeLatest(getSingleBlogsRequest.type, getSingleBlogsWorker);
}
function* watchUpdateBlog() {
  yield takeLatest(updateBlogsRequest.type, updateBlog);
}
function* watchDeleteBlog() {
  yield takeLatest(deleteBlogsRequest.type, deleteBlogWorker);
}
export {
  watchAddBlog,
  watchGetAllBlogs,
  watchGetSingleBlogs,
  watchUpdateBlog,
  watchDeleteBlog,
};
