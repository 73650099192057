import React, { useEffect } from "react";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";

import TableRow from "@mui/material/TableRow";
import { useDispatch, useSelector } from "react-redux";
import { getAllUserRequest } from "../../../redux/slice/userSlice";

const columns = [
  { id: "count", label: "#", minWidth: 50 },
  { id: "firstname", label: "First Name", minWidth: 50 },
  { id: "lastname", label: "Last Name", minWidth: 50 },
  { id: "email", label: "Email", minWidth: 50 },
  { id: "phone", label: "Phone", minWidth: 50 },
  { id: "address", label: "Address", minWidth: 50 },
  { id: "city", label: "City", minWidth: 50 },
  { id: "postalcode", label: "PostalCode", minWidth: 50 },
  // { id: "action", label: "Action", minWidth: 50 },
];

const UserList = () => {
  const dispatch = useDispatch();
  const AlluserData = useSelector((state) => state.user.users);

  const handleEdit = (user) => {
    console.log("the user is :", user);
  };
  const handleDelete = (id) => {
    console.log("the user Id is :", id);
  };
  useEffect(() => {
    dispatch(getAllUserRequest());
  }, []);

  return (
    <>
      <div>
        <div className="text-center">
          <h6 className="text-4xl font-bold my-10">List of All Users</h6>
        </div>
        <div className="flex justify-center">
          <Paper sx={{ width: "80%", overflow: "hidden" }}>
            <TableContainer sx={{ maxHeight: 500 }}>
              <Table className=" " stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align="left" // Align the headers to the left
                        style={{
                          minWidth: column.minWidth,
                          backgroundColor: "#46B035",
                          color: "white",
                          fontSize: "16px",
                          border: "1px solid #ddd",
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {AlluserData?.users &&
                    AlluserData.users.map((user, index) => (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={user._id}
                      >
                        {columns.map((column) => {
                          const value = user[column.id];
                          return (
                            <TableCell
                              key={column.id}
                              align="left"
                              style={{
                                border: "1px solid #ddd",
                              }}
                            >
                              {column.id === "count" ? (
                                index + 1
                              ) : column.id === "action" ? (
                                <div>
                                  <button
                                    className="p-1 m-2 rounded-md"
                                    style={{
                                      backgroundColor: "red",
                                      color: "white",
                                      fontSize: "16px",
                                      border: "1px solid #ddd",
                                    }}
                                    onClick={() => handleDelete(user._id)}
                                  >
                                    Delete
                                  </button>
                                  <button
                                    className=" p-1 m-2 rounded-lg"
                                    onClick={() => handleEdit(user)}
                                    style={{
                                      backgroundColor: "#46B035",
                                      color: "white",
                                      fontSize: "16px",
                                      border: "1px solid #ddd",
                                    }}
                                  >
                                    Edit
                                  </button>
                                </div>
                              ) : (
                                value
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </div>
      </div>
    </>
  );
};

export default UserList;
