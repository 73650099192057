import { createSlice } from "@reduxjs/toolkit";

const orderSlice = createSlice({
  name: "order",
  initialState: {
    orders: [],
    order: null,
    singleUserOrders: [],
    loading: false,
    error: null,
    successMessage: false,
  },
  reducers: {
    addOrderRequest: (state) => {
      state.loading = true;
      state.error = null;
      state.successMessage = false;
    },
    addOrderSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.order = action.payload;
      state.successMessage = true;
    },
    addOrderFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.success = false;
      state.successMessage = false;
    },
    getAllOrderRequest: (state) => {
      state.loading = true;
      state.error = null;
    },
    getAllOrderSuccess: (state, action) => {
      state.loading = false;
      state.orders = action.payload;
      state.error = null;
    },
    getAllOrderFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    updateOrderRequest: (state) => {
      state.loading = true;
      state.error = null;
      state.success = false;
      state.successMessage = false;
    },
    updateOrderSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.success = true;
      state.order = action.payload;
      state.successMessage = true;
    },
    updateOrderFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    getSingleOrderRequest: (state) => {
      state.loading = true;
      state.error = null;
    },
    getSingleOrderSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.success = true;
      state.singleUserOrders = action.payload;
    },
    getSingleOrderFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    deleteOrderRequest: (state) => {
      state.loading = true;
      state.error = null;
      state.successMessage = false;
    },
    deleteOrderSuccess: (state) => {
      state.loading = false;
      state.error = null;
      state.successMessage = true;
    },
    deleteOrderFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.successMessage = false;
    },
  },
});
export const {
  addOrderRequest,
  addOrderSuccess,
  addOrderFailure,
  getAllOrderRequest,
  getAllOrderSuccess,
  getAllOrderFailure,
  getSingleOrderRequest,
  getSingleOrderSuccess,
  getSingleOrderFailure,

  updateOrderRequest,
  updateOrderSuccess,
  updateOrderFailure,

  deleteOrderRequest,
  deleteOrderSuccess,
  deleteOrderFailure,
} = orderSlice.actions;
export default orderSlice.reducer;
