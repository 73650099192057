export const servicedata = [
  { service: "Dry Cleaning", id: "1" },
  { service: "Laundry _ Wash to Finishing & Fold", id: "2" },
  { service: "Laundry_Wash to Finishing & Hanger", id: "6" },
  { service: "Shirt Service", id: "3" },
  { service: "Home & Bedding", id: "4" },
  { service: "Wedding Dresses", id: "5" },
  { service: "Corporate Service / Buisness Services", id: "7" },
  { service: "Overalls / Workwear Regular", id: "8" },
  { service: "Overalls / Workwear Once Off", id: "9" },
];
export const data = [
  { name: "Men shirt - on hanger", from: "70.00" },
  { name: "Trousers (Silk)", from: "182.85" },
  { name: "Suit jacket (suit bundle)", from: "208.15" },
  { name: "Sarie One (1) Piece ", from: "486.56" },
  { name: "Dress long (Beaded)", from: "523.60" },
];
export const data2 = [
  { name: "Blouse Knitwear", from: "134.00" },
  { name: "Active Jogging", from: "131.10" },
  { name: "Waistcoat (suit bundle)", from: "151.80" },
  { name: "Duvet cover - Single", from: "28.50" },
  { name: "Duvet cover - Double", from: "36.10" },
];
export const data3 = [
  { name: "Active Jogging", from: "131.10" },
  { name: "Blouse Soft Silk", from: "181.70" },
  { name: "Blouse Soft", from: "132.01" },
  { name: "Blouse Evening (Detailed)", from: "184.00" },
  { name: "Blouse Knitwear", from: "134.00" },
  { name: "Jersey Long Sleeve", from: "155.10" },
  { name: "Jersey Long Sleeve (Pringle)", from: "184.00" },
  { name: "Shirt (Linen)", from: "193.00" },
  { name: "Shirt Ordinary (Collar)", from: "193.00" },
  { name: "Shirt T-Shirt", from: "161.00" },
  { name: "Sweater (Pringle)", from: "129.95" },
  { name: "Sweater Ordinary", from: "124.30" },
  { name: "Overall Top", from: "146.30" },
  { name: "Waistcoat (suit bundle)", from: "151.80" },
  { name: "Waistcoat", from: "151.80" },
  { name: "Waistcoat Suede", from: "625.60" },
  { name: "Waistcoat Leather", from: "625.60" },
];

export const data4 = [
  { name: "Suit trousers (suit bundle)", from: "182.85" },
  { name: "Trousers", from: "182.85" },
  { name: "Trousers (Silk)", from: "182.85" },
  { name: "Bermuda shorts", from: "173.65" },
  { name: "Suede Trouser", from: "744.05" },
  { name: "Suede Shorts", from: "409.40" },
  { name: "Active Trousers", from: "131.10" },
  { name: "Leather Shorts", from: "409.40" },
  { name: "Leather Trousers", from: "741.75" },
];
export const data5 = [
  { name: "Dress long (Beaded)", from: "523.60" },
  { name: "Dress Long (Plain)", from: "294.40" },
  { name: "Dress Ordinary ", from: "326.60" },
  { name: "Dress Evening (Detailed) ", from: "1459.70" },
  { name: "Dress Evening (Simple)", from: "1109.10" },
  { name: "Dress Cocktail (Simple)", from: "815.00" },
  { name: "Dress Leather", from: "1330.55" },
  { name: "Dress Kids Ordinary ", from: "191.39" },
  { name: "Dress Pleated ", from: "376.05" },
  { name: "Sarie One (1) Piece ", from: "486.56" },
  { name: "Sarie One (2) Piece ", from: "973.13" },
  { name: "Sarie One (3) Piece ", from: "1459.70" },
  { name: "Skirt Ordinary ", from: "200.10" },
  { name: "Skirt Detailed ", from: "233.21" },
  { name: "Skirt Pleated", from: "243.80" },
  { name: "Skirt Leather", from: "744.05" },
  { name: "Suede Skirt", from: "744.05" },
  { name: "Suede Dress", from: "1339.75" },
  { name: "Jumpsuit (short)", from: "326.60" },
  { name: "Jumpsuit (long)", from: "523.60" },
  { name: "Wedding Gown", from: "1526.05" },
  { name: "Wedding Gown Veil", from: "210.45" },
  { name: "Wedding Gown Train", from: "369.15" },
];
export const data6 = [
  { name: "Suit jacket (suit bundle)", from: "208.15" },
  { name: "Suit Child’s Two (2) Piece ", from: "316.25" },
  { name: "Jacket", from: "279.45" },
  { name: "Jacket Corporate", from: "115.49" },
  { name: "Jacket Ordinary", from: "132.01" },
  { name: "Jacket Blazer", from: "132.01" },
  { name: "Jacket Ski Winter", from: "232.30" },
  { name: "Jacket with Belt", from: "264.50" },
  { name: "Jacket Lumber", from: "332.35" },
  { name: "Coat Ordinary", from: "339.25" },
  { name: "Coat Long ", from: "386.40" },
  { name: "Coat Fake Fur ", from: "315.10" },
  { name: "Coat Fake Fur Collar", from: "315.10" },
  { name: "Coat Fake Fur Cuff", from: "315.10" },
  { name: "Coat Woolen ", from: "339.25" },
  { name: "Coat down feather filled ", from: "783.00" },
  { name: "Coat Burberry Raincoat", from: "1133.00" },
  { name: "Coat Leather", from: "1583.55" },
  { name: "Coat Leather 3/4 Height", from: "1216.70" },
  { name: "Coat Suede", from: "1583.55" },
  { name: "Coat Suede 3/4 Height", from: "1216.70" },
  { name: "Robe Judge or Graduation Gown", from: "407.10" },
  { name: "Robe Ordinary Gown", from: "331.20" },
  { name: "Robe Dressing Gown", from: "407.10" },
  { name: "Moncler / Canada Goose", from: "1251.25" },
  { name: "Suede Jacket", from: "1062.60" },
  { name: "Leather Jacket", from: "1084.45" },
];
export const suitdata = [
  { name: "Suit Men's 2 Pce", from: "391.00" },
  { name: "Suit Men's 3 Pce", from: "463.45" },
  { name: "Suit Woman's 2 Pce", from: "391.00" },
  { name: "Suit Woman's 3 Pce", from: "463.45" },
  { name: "Suit 2 Pce Ski Winter", from: "387.55" },
  { name: "Suit Leather Motor Bike", from: "1743.40" },
  { name: "Suit Active Jogging", from: "262.00" },
];
export const data7 = [
  { name: "Ski Coat", from: "670.00" },
  { name: "Ski jacket", from: "556.00" },
  { name: "Ski trousers", from: "190.90" },
  { name: "Ski gloves", from: "152.95" },
  { name: "Ski Cap/Hat", from: "110.00" },
];
export const data8 = [
  { name: "Tie Bow", from: "113.00" },
  { name: "Tie Corporate", from: "129.95" },
  { name: "Tie Cumberland", from: "129.95" },
  { name: "Tie Silk", from: "139.96" },
  { name: "Gloves Silk", from: "139.96" },
  { name: "Gloves", from: "R105.60" },
  { name: "Gloves Leather", from: "R286.35" },
  { name: "Gloves Suede", from: "R284.05" },
  { name: "Brasserie Underwear", from: "R129.95" },
  { name: "Underwear Boxers", from: "R124.30" },
  { name: "Underwear Vest", from: "R129.95" },
  { name: "Scarf/Shawl", from: "R181.70" },
  { name: "Scarf Pashmina", from: "R263.35" },
  { name: "Handbag", from: "R466.41" },
  { name: "Hat/Cap ", from: "R110.40" },
  { name: "Hat/Cap Leather ", from: "R233.45" },
  { name: "Legging Tights / Stockings", from: "R35.00" },
  { name: "Mask", from: "R110.40" },
  { name: "Hankerchief", from: "R113.00" },
  { name: "Belt Suede", from: "R154.10" },
  { name: "Belt Leather", from: "R156.40" },
  { name: "Socks Pair", from: "R35.00" },
  { name: "Suit Cover", from: "R296.00" },
];

// Shirt services start
export const data9 = [
  { name: "Men shirt - on hanger", from: "70.00" },
  { name: "Men shirt - folded", from: "100.00" },
  { name: "Ladies shirt - on hanger", from: "88.56" },
  { name: "Ladies shirt - folded", from: "118.0" },
];
export const data10 = [
  { name: "Men shirt - on hanger", from: "81.75" },
  { name: "Men shirt - folded", from: "111.00" },
  { name: "Ladies shirt - on hanger", from: "100.00" },
  { name: "Ladies shirt - folded", from: "129.00" },
];
// laundry wash services start
export const data11 = [
  { name: " Price per kg - No Brands", from: "R134.80/kg" },
  { name: "    Price per kg - Delux Brands", from: "R141.45/kg" },
];
export const data12 = [
  { name: "  Price per kg - Delux Brands", from: "R75.70/kg" },
  { name: "    Price per kg - No Brands", from: "R75.90/kg" },
];
export const FrequentlyData = [
  { name: "    Price per kg - Delux Brands", from: "R55.00/kg" },
  { name: "      Price per kg - No Brands", from: "R50.00/kg" },
];
export const PressingData = [
  { name: "   Pressing Coat", from: "R110.00" },
  { name: "   Pressing Dress", from: "R110.00" },
  { name: "   Pressing Jacket", from: "R110.00" },
  { name: "   Pressing Pants", from: "R110.00" },
  { name: "   Pressing Shirt", from: "R110.00" },
  { name: "   Pressing Skirt", from: "R110.00" },
  { name: "     Pressing Wedding Dress", from: "R385.01" },
];
export const LoundryWashDataOnce = [
  { name: " Price per kg - No Brands", from: "R141.90/kg" },
  { name: "    Price per kg - Delux Brands", from: "R148.90/kg" },
];
export const LoundryWashDataRegular = [
  { name: " Price per kg - No Brands", from: "R75.90/kg" },
  { name: "    Price per kg - Delux Brands", from: "R79.69/kg" },
];
export const LoundryWashDataFrequently = [
  { name: " Price per kg - No Brands", from: "R69.69/kg" },
  { name: "    Price per kg - Delux Brands", from: "R55.90" },
];

// home and bedding services
export const data13 = [
  { name: "Flat sheet - Single", from: "R17.50" },
  { name: "Flat sheet - Double", from: "18.00" },
  { name: "Flat sheet - King", from: "25.00" },
];
export const data14 = [
  { name: "Fitted sheet - Single", from: "17.50" },
  { name: "Fitted sheet - Double", from: "18.00" },
  { name: "Fitted sheet - King", from: "25.00" },
];
export const data15 = [
  { name: "Duvet cover - Single", from: "28.50" },
  { name: "Duvet cover - Double", from: "36.10" },
  { name: "Duvet cover - King", from: "36.10" },
];
export const data16 = [
  { name: "Mattress covers - Single", from: "27.50" },
  { name: "Mattress covers - Double", from: "37.50" },
  { name: "Mattress covers - King", from: "37.50" },
];
export const data17 = [
  { name: "Duvets Inner / KG", from: "R129.00" },
  { name: "Duvet Inner Feather 3/4 Single", from: "R463.45" },
  { name: "Duvet Inner Feather Queen/ King", from: "R756.70" },
  { name: "Sleeping Bag / KG", from: "R148.35" },
];

export const data18 = [
  { name: "Bedspread Quilted - Single/KG", from: "R141.90" },
  { name: "Bedspread Quilted - Double /KG", from: "R148.35" },
  { name: "Bedspread Quilted - King/KG", from: "R148.35" },
  { name: "Bedspreads Comforter - Single/KG", from: "R148.35" },
  { name: "Bedspreads Comforter - Double/KG", from: "R154.80" },
  { name: "Bedspreads Comforter - King/KG", from: "R154.80" },
  { name: "Blankets - Single Layers / KG", from: "R141.90" },
  { name: "Blankets - Double Layers / KG", from: "R148.35" },
];
export const data19 = [
  { name: "Pillow Standard", from: "R129.00" },
  { name: "Pillow Feather", from: "R148.35" },
  { name: "Pillow Conti", from: "R129.00" },
  { name: "Pillow Case Standard", from: "R15.00" },
  { name: "Pillow Case Oxford", from: "R15.00" },
  { name: "Pillow Case Conti", from: "R15.00" },
  { name: "Pillow Protector", from: "R110.40" },
];
export const data20 = [
  { name: "Hand towel", from: "17.00" },
  { name: "Large towel", from: "18.00" },
  { name: "Extra Large towel", from: "25.00" },
];
export const data21 = [
  { name: "Curtains, Unlined", from: "288.00" },
  { name: "Curtains, Lined", from: "363.00" },
  { name: "Curtains, Heavy / Interlined", from: "388.00" },
  { name: "Curtains, High Value", from: "450.00" },
];
export const data22 = [
  { name: "Rugs, ordinary material", from: "R141.90" },
  { name: "Rugs, wool", from: "952.00" },
  { name: "Rugs, silk/ delicate", from: "1076.39" },
  { name: "Rug repair service (incl fine persian rugs)", from: "RQuote" },
];
export const data23 = [
  { name: "Cushion Cover, Small", from: "R141.90" },
  { name: "Cushion Cover, Medium", from: "148.35" },
  { name: "Cushion Cover, Large", from: "148.35" },
  {
    name: "Settee, 2-seat Canvas (15000.0 > 20000 Rub Count)",
    from: "R724.00",
  },
  {
    name: "Settee, 3-seat Canvas (15000.0 > 20000 Rub Count)",
    from: "R1000.00",
  },
  { name: "Arm Caps", from: "R141.90" },
  { name: "Seat Cover, Small", from: "R141.90" },
  { name: "Seat Cover, Medium", from: "R148.35" },
  { name: "Seat Cover, Large", from: "R148.35" },
  { name: "Seat Cover Sheepskin/KG", from: "R304.75" },
];
export const data24 = [
  { name: "Napkins", from: "45.00" },
  { name: "Table cloth < 1.5sqm", from: "238.00" },
  { name: "Table cloth 1.5sqm-2sqm", from: "284.00" },
  { name: "Table cloth > 2sqm", from: "325.00" },
  { name: "Table napkin", from: "61.00" },
  { name: "Banquet", from: "400.00" },
  { name: "Sheepskin", from: "1133.16" },
];
export const CorporateServiceData = [
  { name: "Serviette", from: "R4.26" },
  { name: "Runners", from: "R12.96" },
  { name: "Overlays", from: "R12.95" },
  { name: "Organza Voil", from: "R12.95" },
  { name: "Tiffany Cushion Covers", from: "R9.30" },
  { name: "Table Cloth 3.5 x 3.5 ", from: "R45.77" },
  { name: "Table Cloth Sequence ", from: "R98.90" },
  { name: "Table Protectors / Underblankets", from: "R15.00" },
  { name: "Drapping / Kg", from: "R184.00" },
  { name: "Drapping / KgTressle Box Conference Stretch", from: "R26.00" },
  { name: "Chair Cover Stretch", from: "R13.93" },
  { name: "Buffet Skirting Pleated", from: "R60.94" },
];
export const WorkWearRegularData = [
  { name: "Overalls Dustcoat > above 100 units ", from: "R17.25" },
  { name: "Overalls Trouser > above 100 units ", from: "R16.10" },
  { name: "Overalls Jacket > above 100 units ", from: "R16.10" },
  { name: "Overalls Jacket < below 100 units", from: "R27.00" },
  { name: "Overalls Trouser < below 100 units", from: "R27.00" },
  { name: "Overalls 2 Pce Suite < below 100 units", from: "R27.00" },
];
export const WorkWearDataOnceOff = [
  { name: "Overalls 2 Pce < below 2 units", from: "R173.65" },
  { name: "Overalls Jacket < below 2 units", from: "R86.82" },
  { name: "Overalls Trousers < below 2 units", from: "R86.82" },
];
