import React from "react";
import OrderList from "../../../components/AdminPannelComp/OrderList/OrderList";

const OrdersList = () => {
  return (
    <>
      <OrderList />
    </>
  );
};

export default OrdersList;
