import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { CartProvider } from "../context/cartContext";
import AdminSidebar from "../components/AdminPannelComp/AdminSidebar";
import { getAllBlogsRequest } from "../redux/slice/blogSlice";
import { getAllServiceRequest } from "../redux/slice/serviceSlice";
import { CircularProgress } from "@mui/material";
const AdminPannel = () => {
  const dispatch = useDispatch();
  const servicesArray = useSelector(
    (state) => state.services.services?.services
  );
  const blogsArray = useSelector((state) => state.blogs.Blogs.blog);
  useEffect(() => {
    console.log("Fetching blogs..");
    dispatch(getAllServiceRequest());
    dispatch(getAllBlogsRequest());
  }, []);
  const isDataAvailable = servicesArray && blogsArray;
  return (
    <>
      <CartProvider>
        <div className="flex h-screen flex-col md:flex-row md:overflow-hidden">
          <div className="w-full flex-none md:w-64">
            <AdminSidebar />
          </div>
          <div className="flex-grow p-6 md:overflow-y-auto md:p-12 bg-[#f9f9f9]">
            <Outlet />
          </div>
        </div>
      </CartProvider>
    </>
  );
};

export default AdminPannel;
