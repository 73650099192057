import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useQuery, gql } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteServiceRequest,
  getAllServiceRequest,
} from "../../../../redux/slice/serviceSlice";
import { Buffer } from "buffer";
import { CircularProgress, DialogContentText } from "@mui/material";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
export default function AllServicesCards() {
  const navigate = useNavigate();
  const servicesArray = useSelector(
    (state) => state.services.services?.services
  );
  const [successfullShowModal, setSuccessfullShowModal] = useState(false);
  const successMessage = useSelector((state) => state.services.successMessage);

  const successfullModalhandleClose = () => {
    setSuccessfullShowModal(false);

    dispatch(getAllServiceRequest());
    setTimeout(() => {
      navigate("/admin-pannel/all-services");
    }, 500);
  };

  const [open, setOpen] = React.useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const handleClickOpen = (id) => {
    console.log("Id ", id);
    setDeleteId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const dispatch = useDispatch();
  const handleDelete = (id) => {
    dispatch(deleteServiceRequest(id));
    setOpen(false);
    setSuccessfullShowModal(true);
  };

  return (
    <>
      {" "}
      <div className="flex flex-wrap -m-4">
        {servicesArray ? (
          servicesArray.map((item) => (
            <div
              className="serviceGrid md:w-1/3  m-6 text-center mx-auto px-6 flex flex-col justify-between"
              key={item?.id}
            >
              <div className=" h-64 overflow-hidden">
                <Link>
                  <img
                    className="object-cover w-full object-center h-full  mx-auto hover:opacity-70"
                    src={
                      item?.cardphoto
                        ? `data:${
                            item?.cardphoto?.contentType
                          };base64,${Buffer.from(
                            item?.cardphoto?.data
                          ).toString("base64")}`
                        : "" // Set an empty string if data is not available
                    }
                    alt={item?.name}
                  />
                </Link>
              </div>
              <div className="bg-[#f9f9f9] h-72 flex flex-col justify-between ">
                <div className="md:p-6 flex flex-col">
                  <div className="h1 text-3xl text-secondary mb-4 hover:text-primary mt-5 md:mt-2">
                    {item?.name}
                  </div>
                  <p className="text-base text-content mb-4">
                    {item?.description.split(" ").slice(0, 9).join(" ")}
                    {item?.description.split(" ").length > 9 ? "..." : ""}
                  </p>
                </div>
                <div className="mt-auto pb-6 flex justify-between items-center my-auto mb-5">
                  <button
                    to={`/admin-pannel/edit-service/${item?._id}`}
                    state={{ item }}
                    className="bg-danger  text-white py-2 px-5 text-sm flex flex-row rounded-md "
                    onClick={() => handleClickOpen(item?._id)}
                  >
                    Delete
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-5 h-5 ml-3 "
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
                      />
                    </svg>
                  </button>
                  <Link
                    to={`/admin-pannel/edit-service/${item?._id}`}
                    state={{ item }}
                    className="bg-content hover:bg-primary text-white py-2 px-5 text-sm flex flex-row rounded-md "
                  >
                    Edit Data
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-5 h-5 ml-3 "
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
                      />
                    </svg>
                  </Link>
                </div>
              </div>
            </div>
          ))
        ) : (
          <>
            <div className=" flex-col flex justify-content-center align-content-center text-center">
              <h1 className="text-center">Loading Services...</h1>
              <CircularProgress color="success" />
              <br />
            </div>
          </>
        )}
      </div>
      <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={successfullShowModal}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          <p className="font-bold "> Delete Service</p>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={successfullModalhandleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          {successMessage ? (
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ minWidth: 400, minHeight: 70 }}
            >
              <h1 className="text-primary text-center mt-5 text-2xl font-bold">
                Service Deleted successfully!
              </h1>
            </div>
          ) : (
            <div
              className="flex-col "
              style={{ minWidth: 400, minHeight: 100 }}
            >
              <h6 className="text-2xl    text-center mt-4">Deleting Service</h6>
              <p className="text-center my-3">
                {/* <Stack sx={{ color: "grey.500" }} spacing={2} direction="row"> */}
                <CircularProgress color="success" />
              </p>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="success"
            autoFocus
            onClick={successfullModalhandleClose}
          >
            Ok
          </Button>
        </DialogActions>
      </BootstrapDialog>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"User Confirmation needed"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are You sure you want to delete this item?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button onClick={() => handleDelete(deleteId)} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
