import React from "react";
import BloComp from "../../../../components/AdminPannelComp/BlogComp/AddBlog";
const CreacteBlog = () => {
  return (
    <div>
      <BloComp />
    </div>
  );
};

export default CreacteBlog;
