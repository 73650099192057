import React from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../../assets/images/logo.png";
import NavLinks from "./navLinks";

// import "./AdminSidebar.scss";
const AdminSidebar = () => {
  const navigate = useNavigate();
  const handleLogout = (event) => {
    event.preventDefault();

    localStorage.removeItem("user");
    localStorage.removeItem("redirectPath");

    navigate("/");
  };
  return (
    <div className="flex h-screen flex-col px-3 py-4 md:px-2 bg-white">
      <Link to="/" className={`font-bold w-full`}>
        <div className="w-full md:w-full flex items-center justify-center gap-2 pb-[12px]">
          <span>
            <img src={logo} width="80px" />
          </span>
          <span className="text-black text-[14px] font-medium">
            Supreme Cleaners
          </span>
        </div>
      </Link>
      <div className="flex grow flex-col justify-between space-x-2  md:space-x-0">
        <NavLinks />

        <div>
          <button
            onClick={handleLogout}
            className="flex h-[48px] grow items-center justify-center gap-2 rounded-md p-3 text-sm font-medium hover:bg-sky-100 hover:text-blue-600 md:flex-none md:justify-start md:p-2 md:px-3"
          >
            {/* <LogoutIcon className="w-6" /> */}
            <div className=" text-2xl font-bold mb-10 hidden md:block logOut text-[#B01212]">
              Log out
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default AdminSidebar;
