import React, { useState } from "react";
import PickUp from "./PickUp";
import DeliverySlot from "./DeliverySlot";

export default function Slot({ deliveryDateParent, pickupDateParent }) {
  const pickupData = (data) => {
    pickupDateParent(data);
    return data;
  };
  const deliveryData = (data) => {
    console.log("Mera Data", data);
    deliveryDateParent(data);

    return data;
  };
  // slotData(pickupData, deliveryData);
  return (
    <div>
      <PickUp pickupData={pickupData} />
      <DeliverySlot deliveryData={deliveryData} />
    </div>
  );
}
