import React from "react";
import EditServiceComp from "../../../../components/AdminPannelComp/AllServicesComp/EditServiceComp";
const EditService = () => {
  return (
    <div>
      <EditServiceComp />
    </div>
  );
};

export default EditService;
