import React, { useState } from "react";
import Slot from "../../components/BookingForm/Slot";
import Address from "../../components/BookingForm/Address";
import Review from "../../components/BookingForm/Review";
import { useDispatch } from "react-redux";
import { addShipmentRequest } from "../../redux/slice/shipmentSlice";

const MultiStepForm = () => {
  const [step, setStep] = useState(1);
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    step1Data: "",
    step2Data: "",
    step3Data: "",
  });
  const [pickupDateValues, setPickupDateValues] = useState();
  const [deliveryDateValues, setDeliveryDateValues] = useState();
  const [collectionAddressValues, setCollectionAddressValues] = useState();
  const [deliveryAddressValues, setDeliveryAddressValues] = useState();
  const [accoutnValues, setAccountValues] = useState();

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("Valurs", pickupDateValues);
    console.log("Valur2", deliveryDateValues);
    console.log("Valur3", collectionAddressValues);
    console.log("Valur3", deliveryAddressValues);
    console.log("Valur4", accoutnValues);
    // Dispatch your Redux action here to make the API call
    dispatch(
      addShipmentRequest({
        pickupDateValues,
        deliveryDateValues,
        deliveryAddressValues,
        collectionAddressValues,
        accoutnValues,
      })
    );
  };

  const nextStep = () => {
    if (step === 1 && deliveryDateValues && pickupDateValues) {
      setStep(step + 1);
    } else if (step === 2 && collectionAddressValues && deliveryAddressValues) {
      setStep(step + 1);
    } else if (step === 3 && accoutnValues) {
      setStep(step + 1);
    } else {
      // setStep(step + 1);
      alert("Fill the fields");
    }
  };

  const prevStep = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  // Calculate the progress percentage
  const progress = (step - 1) * 33; // Assuming 3 steps, so each step contributes 50% to the progress
  const pickupDateParent = (pickupData) => {
    setPickupDateValues(pickupData);
    console.log("pickupData Data", pickupData);
  };
  const deliveryDateParent = (deliveryData) => {
    setDeliveryDateValues(deliveryData);
    console.log("deliveryData Data", deliveryData);
  };
  const addressDataValues = (collectionData, deliveryData) => {
    // setAddressValues(addressDataValues);
    setCollectionAddressValues(collectionData);
    setDeliveryAddressValues(deliveryData);

    console.log("addressDataValues Data AAAAAA", collectionData);
    console.log("addressDataValues Data AAAAAA", deliveryData);
  };
  const accountDataValues = (accountData) => {
    setAccountValues(accountData);
    console.log("Account Data Values", accountData);
  };

  return (
    <div className="max-w-4xl mx-auto mt-8 p-4 ">
      <div className="bg-white  p-5 rounded-md shadow-md">
        <h1 className="text-2xl font-semibold mb-4">Step {step} of 3</h1>
        <div className="relative h-4 rounded-full ">
          <div className="bg-content bg-opacity-10 w-full h-1 rounded-full" />
          <div
            className="absolute top-0 left-0 bg-primary h-1 rounded-full"
            style={{ width: `${progress}%` }}
          />
        </div>
      </div>

      <div className="py-5">
        {step === 1 && (
          <Slot
            pickupDateParent={pickupDateParent}
            deliveryDateParent={deliveryDateParent}
          />
        )}
        {step === 2 && <Address addressDataValues={addressDataValues} />}
        {step === 3 && <Review accountDataValues={accountDataValues} />}
        <div className="mt-4 flex justify-center items-center gap-6">
          {step > 1 && (
            <button
              onClick={prevStep}
              className="bg-primary text-white pm py-2 px-7 rounded-md my-6"
            >
              Previous
            </button>
          )}
          {step < 3 ? (
            <button
              onClick={nextStep}
              className="bg-primary text-white pm py-2 px-7 rounded-md my-6"
            >
              Next
            </button>
          ) : (
            <button
              className="bg-primary text-white pm py-2 px-7 rounded-md my-6"
              onClick={handleSubmit}
            >
              Save & Continue
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default MultiStepForm;
