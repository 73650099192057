import { takeLatest, put, call } from "redux-saga/effects";
import axios from "axios";
import toast from "react-hot-toast";
import { baseUrl } from "./apiUrl";
import {
  addServiceRequest,
  addServiceSuccess,
  addServiceFailure,
  getAllServiceRequest,
  getAllServiceSuccess,
  getAllServiceFailure,
  getSingleServiceRequest,
  getSingleServiceSuccess,
  getSingleServiceFailure,
  updateServiceRequest,
  updateServiceSuccess,
  updateServiceFailure,
  deleteServiceRequest,
  deleteServiceSuccess,
  deleteServiceFailure,
} from "../slice/serviceSlice";

function* addService(action) {
  try {
    //console.log("Clicked");
    //const formData = action.payload;
    //console.log("Service:", action.payload);

    // Make the API call with axios
    const response = yield call(
      axios.post,
      `${baseUrl}/api/v1/service/addservices`,
      action.payload,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    yield put(addServiceSuccess(response.data));
    console.log("Service :", response.data);
  } catch (error) {
    yield put(addServiceFailure(error.message));
  }
}
function* getAllServiceWorker() {
  try {
    // console.log("Saga Clicked Service");
    const response = yield call(
      axios.get,
      `${baseUrl}/api/v1/service/getAllServices`
    );
    yield put(getAllServiceSuccess(response.data));
    // console.log("All Service are :", response.data);
  } catch (error) {
    yield put(getAllServiceFailure(error.message));
  }
}

function* getSingleServiceWorker(action) {
  try {
    console.log("Single  Clicked Service");
    const id = action.payload;
    const response = yield call(
      axios.get,
      `${baseUrl}/api/v1/service/getSingleService/${id}`
    );
    yield put(getSingleServiceSuccess(response.data));
    console.log(" Single Service is  :", response.data);
  } catch (error) {
    yield put(getSingleServiceFailure(error.message));
  }
}
function* updateService(action) {
  try {
    const { formdata, id } = action.payload;
    console.log("Update service", action.payload);
    console.log("id :", id);
    console.log("formdata :", formdata);
    const response = yield call(
      axios.put,
      `${baseUrl}/api/v1/service/updateService/${id}`,
      formdata
    );
    yield put(updateServiceSuccess(response.data));
  } catch (error) {
    put(updateServiceFailure(error.message));
  }
}
function* deleteServiceWorker(action) {
  try {
    const id = action.payload;
    yield call(axios.delete, `${baseUrl}/api/v1/service/deleteService/${id}`);
    yield put(deleteServiceSuccess());
  } catch (error) {
    yield put(deleteServiceFailure(error.message));
  }
}
function* watchAddService() {
  yield takeLatest(addServiceRequest.type, addService);
}
function* watchGetAllService() {
  yield takeLatest(getAllServiceRequest.type, getAllServiceWorker);
}
function* watchGetSingleService() {
  yield takeLatest(getSingleServiceRequest.type, getSingleServiceWorker);
}
function* watchUpdateService() {
  yield takeLatest(updateServiceRequest.type, updateService);
}
function* watchDeleteService() {
  yield takeLatest(deleteServiceRequest.type, deleteServiceWorker);
}
export {
  watchAddService,
  watchGetAllService,
  watchUpdateService,
  watchGetSingleService,
  watchDeleteService,
};
