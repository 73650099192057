import React from "react";
import UserList from "../../../components/AdminPannelComp/UserList/UserList";

const UsersList = () => {
  return (
    <>
      <UserList />
    </>
  );
};

export default UsersList;
