import Layout from "../layout";
// import ClientPanel from "../layout/clientpanel";

import { createBrowserRouter } from "react-router-dom";
import Home from "../components/home/index";
import Error404 from "../pages/Error404";
import Services from "../pages/services/Services";
import Prices from "../pages/Prices";
import Privacy from '../pages/Privacy'
import BusinessServices from "../pages/services/BusinessServices";
import Blog from "../pages/Blogs/Blog";
import SingleBlog from "../pages/Blogs/SingleBlog";
import About from "../pages/About";
import Contact from "../pages/Contact";
import RefundPolicy from "../pages/RefundPolicy";
import TermAndConditions from "../pages/TermAndConditions";
import FAQ from "../pages/FAQ";
import Process from "../pages/Process";
import Booking from "../pages/Booking";
import WeddingDresses from "../pages/services/WeddingDresses";
import LoginPage from "../pages/userPages/LoginPage";
import Cart from "../pages/paymentPages/CartPage";
import CheckOut from "../pages/paymentPages/CheckOutPage";

import SingalService from "../pages/services/SingalService";
import ThankYou from "../pages/paymentPages/ThankYou";
import MyAccountPage from "../pages/userPages/MyAccountPage";
import BookingForm from "../pages/Booking/MultiStepForm";

import Register from "../pages/userPages/Register";
import Forgot from "../pages/userPages/Forgot";
import ChangePassword from "../pages/userPages/ChangePassword";
import RecurringBooking from "../pages/userPages/Accountpages/RecurringBooking";
import PersonalInfo from "../pages/userPages/Accountpages/PersonalInfo";
import Preferences from "../pages/userPages/Accountpages/Preferences";
import Help from "../pages/userPages/Accountpages/Help";
//Admin Panne;
import AdminPannelLayout from "../layout/AdminPannelLayout";
import AdminPannel from "../pages/AdminPannel";
import AddService from "../pages/AdminPannel/Services/AddService";
import AllServices from "../pages/AdminPannel/Services/AllServices";
import CreacteBlog from "../pages/AdminPannel/Blogs/CreateBlog";
import EditService from "../pages/AdminPannel/Services/EditService";
import AllBlogs from "../pages/AdminPannel/Blogs/AllBlogs";
import UpdateBlog from "../pages/AdminPannel/Blogs/UpdateBlog";
import UsersList from "../pages/AdminPannel/UserList/UsersList";
import OrdersList from "../pages/AdminPannel/OrdersList/OrdersList";
import AdminProfile from "../pages/AdminPannel/AdminProfile/AdminProfile";
import UpdateAdminProfile from "../pages/AdminPannel/AdminProfile/UpdateAdminProfile";
import AddNewPassword from "../pages/userPages/AddNewPassword";
import Changepassword from "../pages/AdminPannel/AdminProfile/Changepassword";
import CancelPage from "../pages/paymentPages/CancelPage";
import WelcomePage from "../pages/AdminPannel/AdminProfile/WelcomePage";
import ProtectedRoute from "../utils/protectedRoutes";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "*",
        element: <Error404 />,
      },
      {
        path: "/home",
        element: <Home />,
      },
      {
        path: "/services",
        element: <Services />,
      },
      {
        path: "/prices",
        element: <Prices />,
      },
      {
        path: "/privacy",
        element: <Privacy />,
      },
      {
        path: "/businessservices",
        element: <BusinessServices />,
      },
      {
        path: "/blog",
        element: <Blog />,
      },
      {
        path: "/blog/:id",
        element: <SingleBlog />,
      },
      {
        path: "/about",
        element: <About />,
      },
      {
        path: "/contact",
        element: <Contact />,
      },
      {
        path: "/refund-policy",
        element: <RefundPolicy />,
      },
      {
        path: "/terms-conditions",
        element: <TermAndConditions />,
      },
      {
        path: "/faq",
        element: <FAQ />,
      },
      {
        path: "/process",
        element: <Process />,
      },
      {
        path: "/booking",
        element: <Booking />,
      },
      {
        path: "/wedding-dresses",
        element: <WeddingDresses />,
      },
      {
        path: "/login",
        element: <LoginPage />,
      },
      {
        path: "/cart",
        element: <Cart />,
      },
      {
        path: "/checkout",
        element: <CheckOut />,
      },

      {
        path: "/service/:slug",
        element: <SingalService />,
      },
      {
        path: "/success",
        element: <ThankYou />,
      },
      {
        path: "/account",
        element: <MyAccountPage />,
      },
      {
        path: "/booking-form",
        element: <BookingForm />,
      },

      {
        path: "/register",
        element: <Register />,
      },
      {
        path: "/forgot-password",
        element: <Forgot />,
      },
      {
        path: "/change-password/",
        element: <ChangePassword />,
      },
      {
        path: "/new-password/:token",
        element: <AddNewPassword />,
      },
      {
        path: "/recurring",
        element: <RecurringBooking />,
      },
      {
        path: "/personal-info",
        element: <PersonalInfo />,
      },
      {
        path: "/preferences",
        element: <Preferences />,
      },
      {
        path: "/help-feedback",
        element: <Help />,
      },

      {
        path: "/cancel",
        element: <CancelPage />,
      },
    ],
  },
  {
    path: "/admin-pannel",

    element: (
      <ProtectedRoute>
        <AdminPannelLayout />
      </ProtectedRoute>
    ),
    children: [
      {
        path: "/admin-pannel/profile",
        element: <AdminProfile />,
      },
      {
        path: "/admin-pannel/welcome",
        element: <WelcomePage />,
      },
      {
        path: "/admin-pannel/editprofile",
        element: <UpdateAdminProfile />,
      },
      {
        path: "/admin-pannel/changePassword",
        element: <Changepassword />,
      },
      {
        path: "/admin-pannel/",
        element: <AdminPannel />,
      },
      {
        path: "/admin-pannel/userlist",
        element: <UsersList />,
      },
      {
        path: "/admin-pannel/orderlist",
        element: <OrdersList />,
      },
      {
        path: "/admin-pannel/add-service",
        element: <AddService />,
      },
      {
        path: "/admin-pannel/all-services",
        element: <AllServices />,
      },
      {
        path: "/admin-pannel/edit-service/:id",
        element: <EditService />,
      },
      {
        path: "/admin-pannel/create-blog",
        element: <CreacteBlog />,
      },
      {
        path: "/admin-pannel/all-blogs",
        element: <AllBlogs />,
      },
      {
        path: "/admin-pannel/update-blogs/:id",
        element: <UpdateBlog />,
      },
    ],
  },
]);

export default router;
