import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { newPasswordStart } from "../../redux/slice/userSlice";
import { useDispatch } from "react-redux";
const AddNewPassword = () => {
  const { token } = useParams();
  const dispatch = useDispatch();
  const [newPassworddata, setNewPassworddata] = useState({
    newPassword: "",
    Con_password: "",
  });

  const handleInput = (e) => {
    const { name, value } = e.target;
    setNewPassworddata({
      ...newPassworddata,
      [name]: value,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch(newPasswordStart({ newPassworddata, token }));
  };
  return (
    <>
      <section>
        <div className="bg-content  bg-opacity-10 shadow-lg">
          <div className="mx-auto max-w-xl py-10 py-10 px-5 ">
            <h1 className="text-4xl text-center">Add New Password </h1>
          </div>
        </div>
        <div className="bg-white shadow-xl rounded-md max-w-xl mx-auto px-8 py-6 my-10 relative">
          <h4 className="text-xl text-primary">Change Password</h4>
          <form noValidate className="py-4 space-y-4" onSubmit={handleSubmit}>
            <div className="password-wrapper">
              <label className="text-content text-sm block pm">
                New Password
              </label>
              <input
                type="password"
                name="newPassword"
                value={newPassworddata.newPassword}
                onChange={handleInput}
                className="w-full border-b border-0 border-content outline-none  text-sm focus:ring-0 focus:ring-offset-0 focus:shadow-none focus:border-b focus:border-b-content"
              />
            </div>
            <div className="password-wrapper">
              <label className="text-content text-sm block pm">
                Confirm New Password
              </label>
              <input
                type="password"
                name="Con_password"
                value={newPassworddata.Con_password}
                onChange={handleInput}
                className="w-full border-b border-0 border-content outline-none  text-sm focus:ring-0 focus:ring-offset-0 focus:shadow-none focus:border-b focus:border-b-content"
              />
            </div>
            <button
              type="submit"
              className="bg-primary text-white pm py-2 px-7 rounded-md my-6"
            >
              Save
            </button>
          </form>
        </div>
      </section>
    </>
  );
};

export default AddNewPassword;
