import React from "react";
import UpdateProfile from "../../../components/AdminPannelComp/Profile/UpdateProfile";

const UpdateAdminProfile = () => {
  return (
    <>
      <UpdateProfile />
    </>
  );
};

export default UpdateAdminProfile;
