import React from "react";
import { Link } from "react-router-dom";

const Profile = () => {
  const userdata = JSON.parse(localStorage.getItem("user"));
  return (
    <>
      <div>
        <div
          className="mt-10 mx-auto w-[50%]  border border-gray-200 rounded-md p-4 shadow-lg"
          style={{ backgroundColor: "#ECECEC", border: "black" }}
        >
          <div className="flex justify-start mt-5">
            <h3 className="text-3xl  font-bold  ">First Name: </h3>
            <p className=" text-2xl ml-16 ">{userdata?.user?.firstname}</p>
          </div>
          <div className="flex justify-start mt-5">
            <h3 className="text-3xl  font-bold  ">Last Name: </h3>
            <p className=" text-2xl ml-16 ">{userdata?.user?.lastname}</p>
          </div>
          <div className="flex justify-start mt-5">
            <h3 className="text-3xl  font-bold  ">Email: </h3>
            <p className=" text-2xl ml-32  ">{userdata?.user?.email}</p>
          </div>
          <div className="flex justify-start mt-5">
            <h3 className="text-3xl  font-bold  ">Phone: </h3>
            <p className=" text-2xl ml-32 ">{userdata?.user?.phone}</p>
          </div>

          <div className=" mt-20 flex justify-between">
            <p>
              <Link
                to="/admin-pannel/changePassword"
                className=" bg-primary py-5 px-11 flex items-center justify-center gap-8 text-white rounded-md uppercase font-semibold  hover:bg-blue-600 focus:outline-none  transition-all text-sm  "
              >
                Change Password
              </Link>
            </p>

            <p>
              <Link
                to="/admin-pannel/editprofile"
                className="bg-primary py-5 px-11 flex items-center justify-center gap-8 text-white rounded-md uppercase font-semibold  hover:bg-blue-600 focus:outline-none  transition-all text-sm  "
              >
                Edit Profile
              </Link>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
