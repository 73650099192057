import { takeLatest, put, call } from "redux-saga/effects";
import axios from "axios";
import {
  addShipmentRequest,
  addShipmentSuccess,
  addShipmentFailure,
} from "../slice/shipmentSlice";
import Slot from "../../components/BookingForm/Slot";

function* addShipment(action) {
  try {
    console.log("Valuesssssss", action.payload);
    const {
      pickupDateValues,
      deliveryDateValues,
      deliveryAddressValues,
      collectionAddressValues,
      accoutnValues,
    } = action.payload;
    const {
      collectionCity,
      collectionEmail,
      collectionInstructions,
      collectionLocalArea,
      collectionMobile,
      collectionName,
      collectionPostCode,
      collectionStreetAddress,
      collectionZone,
    } = collectionAddressValues;
    const {
      deliveryCity,
      deliveryEmail,
      deliveryInstructions,
      deliveryLocalArea,
      deliveryMobile,
      deliveryName,
      deliveryPostCode,
      deliveryStreetAddress,
      deliveryZone,
    } = deliveryAddressValues;
    const { selectedPickupDate, pickupSlot } = pickupDateValues;
    let pickupTimeString = pickupSlot;
    let timeArray = pickupTimeString.split("-");

    let collectionAfter = timeArray[0]; // "10"
    let collectionBefore = timeArray[1].split(" ")[0];

    const { selectedDeliveryDate, deliverySlot } = deliveryDateValues;
    let deliveryTimeString = pickupSlot;
    let deliverytimeArray = deliveryTimeString.split("-");

    let deliveryAfter = deliverytimeArray[0]; // "10"
    let deliveryBefore = deliverytimeArray[1].split(" ")[0];

    // const {selectedDate,slot} = deliveryValues;
    const response = yield call(
      axios.post,
      "https://api.shiplogic.com/v2/shipments",
      {
        collection_address: {
          type: "",
          company: "uAfrica.com",
          street_address: collectionStreetAddress,
          local_area: collectionLocalArea,
          city: collectionCity,
          code: collectionPostCode,
          zone: collectionZone,
          // country: "ZA",
          // lat: -25.7863272,
          // lng: 28.277583,
        },
        collection_contact: {
          name: "Cornel Rautenbach",
          mobile_number: collectionMobile,
          email: collectionEmail,
        },
        delivery_address: {
          type: "",
          company: "",
          street_address: deliveryStreetAddress,
          local_area: deliveryLocalArea,
          city: deliveryCity,
          code: deliveryPostCode,
          zone: deliveryZone,
          country: "ZA",
          // lat: -25.80665579999999,
          // lng: 28.334732,
        },
        delivery_contact: {
          name: deliveryName,
          mobile_number: deliveryMobile,
          email: deliveryEmail,
        },
        parcels: [
          {
            parcel_description: "Standard flyer",
            submitted_length_cm: 20,
            submitted_width_cm: 20,
            submitted_height_cm: 10,
            submitted_weight_kg: 2,
          },
        ],
        opt_in_rates: [],
        opt_in_time_based_rates: [76],
        special_instructions_collection: collectionInstructions,
        special_instructions_delivery: deliveryInstructions,
        declared_value: 1100,
        collection_min_date: "2021-05-21T00:00:00.000Z",
        collection_after: "08:00",
        collection_before: "16:00",
        delivery_min_date: "2021-05-21T00:00:00.000Z",
        delivery_after: "10:00",
        delivery_before: "17:00",
        // collection_min_date: selectedPickupDate,
        // // collection_after: ${collectionAfter}:00,
        // // collection_before: ${collectionBefore}:00,
        // collection_after: "8:00",
        // collection_before: "8:00",
        // // delivery_min_date: "2021-05-21T00:00:00.000Z",
        // delivery_min_date: selectedDeliveryDate,
        // // delivery_after: ${deliveryAfter}:00,
        // // delivery_before: ${deliveryBefore}:00,
        // delivery_after: "8:00",
        // delivery_before: "8:00",
        custom_tracking_reference: "",
        customer_reference: "ORDERNO123",
        service_level_code: "ECO",
        mute_notifications: false,
      },
      {
        headers: {
          Authorization: "Bearer 76d6f3bac59d4c7caee4e23426468628",
        },
      }
    );
    yield put(addShipmentSuccess(response.data));
    console.log("Response", response);
  } catch (error) {
    console.log("Error in Shipment", error);
    yield put(addShipmentFailure(error));
  }
}

function* watchAddShipment() {
  yield takeLatest(addShipmentRequest.type, addShipment);
}
export { watchAddShipment };
