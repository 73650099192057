import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Outlet } from "react-router-dom";
import { CartProvider } from "../context/cartContext";
import { getAllBlogsRequest } from "../redux/slice/blogSlice";
import { getAllServiceRequest } from "../redux/slice/serviceSlice";
import { CircularProgress } from "@mui/material";

const Layout = () => {
  const dispatch = useDispatch();
  const servicesArray = useSelector(
    (state) => state.services.services?.services
  );
  const blogsArray = useSelector((state) => state.blogs.Blogs.blog);
  useEffect(() => {
    dispatch(getAllServiceRequest());
    dispatch(getAllBlogsRequest());
  }, []);

  return (
    <>
      <>
        <CartProvider>
          <Navbar />
          <Outlet />
          <Footer />
        </CartProvider>
      </>
    </>
  );
};

export default Layout;
