import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getAllBlogsRequest } from "../redux/slice/blogSlice";
import { Buffer } from "buffer";
import { CircularProgress } from "@mui/material";

export default function BlogCard() {
  const dispatch = useDispatch();
  const blogsArray = useSelector((state) => state.blogs.Blogs.blog);
  console.log("New Array :", blogsArray);

  if (blogsArray === null) {
    return <p>Loading blogs...</p>;
  }
  return (
    <>
      {blogsArray ? (
        blogsArray.map((item) => (
          <div className=" md:w-1/3  m-6  mx-auto px-6 ">
            <>
              <div className="blogCArd h-64 overflow-hidden">
                <Link to={`/blog/${item._id}`} state={{ item }}>
                  <img
                    className="object-cover w-full object-center h-full mx-auto hover:opacity-70"
                    src={
                      item?.cardPhoto
                        ? `data:${
                            item?.cardPhoto?.contentType
                          };base64,${Buffer.from(
                            item?.cardPhoto?.data
                          ).toString("base64")}`
                        : ""
                    }
                    alt={item?.name}
                  />
                </Link>
              </div>
              <div className="bg-[#f9f9f9] h-72">
                <div className="p-6 flex flex-col">
                  <h1 className="h1 text-sm  mb-4 h text-primary ">Our Blog</h1>

                  <Link
                    to={`/blog/${item._id}`}
                    state={{ item }}
                    className="h1 text-3xl text-secondary mb-4 hover:text-primary "
                  >
                    {item?.name}
                  </Link>

                  <p className="text-base text-content mb-4">{}</p>

                  <Link
                    to={`/blog/${item._id}`}
                    state={{ item }}
                    className="text-secondary underline font-bold hover:text-primary h1  py-2 mb-12 text-sm flex flex-row rounded-l-md my-auto mb-2"
                  >
                    Read more
                  </Link>
                </div>
              </div>
            </>
          </div>
        ))
      ) : (
        <>
          <div className="mx-auto flex my-5 ">
            <h6 className="text-4xl font-bold mr-3 ">Loading Blogs</h6>

            <CircularProgress color="success" />
          </div>
        </>
      )}
    </>
  );
}
