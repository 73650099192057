import React from "react";
import UpdateBlogCom from "../../../components/AdminPannelComp/BlogComp/UpdateBlogCom";

const UpdateBlog = () => {
  return (
    <div>
      <UpdateBlogCom />
    </div>
  );
};

export default UpdateBlog;
