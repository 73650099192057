import React from "react";
// import AllServicesComp from "../../../components/AdminPannelComp/AllServicesComp";
import AllServicesCards from "../../../../components/AdminPannelComp/AllServicesComp/AllServicesCard";
import { useDispatch, useSelector } from "react-redux";
import { getSingleServiceRequest } from "../../../../redux/slice/serviceSlice";

import { Buffer } from "buffer";
const AllServices = () => {
  return (
    <div>
      {/* <AllServicesComp /> */}
      <AllServicesCards />
    </div>
  );
};

export default AllServices;
