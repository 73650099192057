import React, { useEffect, useState } from "react";
import SideBar from "../../components/SideBar";
import { Tab } from "@headlessui/react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getSingleOrderRequest } from "../../redux/slice/orderSlice";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const columns = [
  { id: "_id", label: "OrderId", minWidth: 50 },
  { id: "name", label: "CreatedBy", minWidth: 50 },
  { id: "email", label: "Email", minWidth: 50 },
  { id: "phone", label: "Phone", minWidth: 50 },
  { id: "address", label: "Address", minWidth: 50 },
  { id: "city", label: "City", minWidth: 50 },
  { id: "postcode", label: "PostalCode", minWidth: 50 },
  { id: "status", label: "Status", minWidth: 50 },
  { id: "amount", label: "Amount", minWidth: 50 },
  { id: "action", label: "Action", minWidth: 50 },
];

export default function MyAccountPage() {
  const dispatch = useDispatch();
  const userOrders = useSelector((state) => state.orders.singleUserOrders);
  const [currentOrders, setCurrentOrders] = useState([]);
  const [pastOrders, setPastOrders] = useState([]);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const currentuser = JSON.parse(localStorage.getItem("user"));
  const userId = currentuser?.user?.id;
  const handleEdit = (user) => {};
  const handleDelete = (id) => {};
  useEffect(() => {
    if (userOrders) {
      const pendingOrders = userOrders.filter(
        (order) => order.status === "pending" || order.status === "processing"
      );
      const successOrders = userOrders.filter(
        (order) => order.status === "completed"
      );
      setCurrentOrders(pendingOrders);
      setPastOrders(successOrders);
    }
  }, [userOrders]);
  useEffect(() => {
    dispatch(getSingleOrderRequest(userId));
  }, [dispatch]);
  return (
    <>
      <section>
        <div className="flex flex-col md:flex-row">
          <div className="basis-full md:basis-3/12">
            <SideBar />
          </div>
          <div className="basis-full md:basis-9/12 p-3">
            <div className="bg-content  bg-opacity-10 ">
              <div className="mx-auto max-w-xl py-10 px-5">
                <h1 className="text-4xl">Your orders </h1>
              </div>
            </div>
            <div>
              <Tab.Group as="div" className="mx-auto max-w-xl">
                <Tab.List className="">
                  <Tab
                    className={({ selected }) =>
                      classNames(
                        "w-2/4  py-2.5 pb",
                        " ring-offset-0  focus:outline-none focus:ring-0",
                        selected ? "border-b border-primary" : " "
                      )
                    }
                  >
                    Current orders ({currentOrders.length})
                  </Tab>
                  <Tab
                    className={({ selected }) =>
                      classNames(
                        "w-2/4  py-2.5 pb",
                        " ring-offset-0  focus:outline-none focus:ring-0",
                        selected ? "border-b border-primary" : " "
                      )
                    }
                  >
                    Past orders {pastOrders.length}
                  </Tab>
                </Tab.List>
                <Tab.Panels>
                  <Tab.Panel className="py-10">
                    {currentOrders.length > 0 ? (
                      <div>
                        <Paper sx={{ width: "full", overflow: "hidden" }}>
                          <TableContainer sx={{ maxHeight: 500 }}>
                            <Table
                              className=" "
                              stickyHeader
                              aria-label="sticky table"
                            >
                              <TableHead>
                                <TableRow>
                                  {columns.map((column) => (
                                    <TableCell
                                      key={column.id}
                                      align="left" // Align the headers to the left
                                      style={{
                                        minWidth: column.minWidth,
                                        backgroundColor: "#46B035",
                                        color: "white",
                                        fontSize: "16px",
                                        border: "1px solid #ddd",
                                      }}
                                    >
                                      {column.label}
                                    </TableCell>
                                  ))}
                                </TableRow>
                              </TableHead>

                              <TableBody>
                                {currentOrders?.map((order) => (
                                  <TableRow
                                    hover
                                    role="checkbox"
                                    tabIndex={-1}
                                    key={order._id}
                                  >
                                    {columns.map((column) => {
                                      const value = order[column.id];
                                      return (
                                        <TableCell
                                          key={column.id}
                                          align="left"
                                          style={{
                                            border: "1px solid #ddd",
                                          }}
                                        >
                                          {column.id === "action" ? (
                                            <div>
                                              <button
                                                className="p-1 m-2 rounded-md"
                                                style={{
                                                  backgroundColor: "red",
                                                  color: "white",
                                                  fontSize: "16px",
                                                  border: "1px solid #ddd",
                                                }}
                                                onClick={() =>
                                                  handleDelete(order._id)
                                                }
                                              >
                                                Delete
                                              </button>
                                              <button
                                                className=" p-1 m-2 rounded-lg"
                                                onClick={() =>
                                                  handleEdit(order)
                                                }
                                                style={{
                                                  backgroundColor: "#46B035",
                                                  color: "white",
                                                  fontSize: "16px",
                                                  border: "1px solid #ddd",
                                                }}
                                              >
                                                Edit
                                              </button>
                                            </div>
                                          ) : (
                                            value
                                          )}
                                        </TableCell>
                                      );
                                    })}
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                          <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={userOrders?.length} // Use the length of AlluserData for pagination
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                          />
                        </Paper>
                      </div>
                    ) : (
                      <div>
                        <h1>No Order is Pending</h1>
                      </div>
                    )}

                    {/* <div className="h-72 flex flex-col justify-center items-center gap-5">
                      <h1 className="text-lg">
                        You don’t have any current orders yet.
                      </h1>
                      <h1 className="text-lg">
                        Schedule your first Home pick up & delivery now!
                      </h1>
                      <Link
                        to="/booking-form"
                        className="bg-primary py-5 px-11 flex items-center justify-center gap-8 text-white rounded-md uppercase font-semibold  hover:bg-blue-600 focus:outline-none  transition-all text-sm  "
                      >
                        New Booking
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-arrow-right"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fillRule="evenodd"
                            d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                          />
                        </svg>
                      </Link>
                    </div>
                    <div>
                      <ul className="flex flex-col gap-5">
                        <li className="shadow-md bg-white gap-3 rounded">
                          <div className="flex justify-end shadow p-4 ">
                            <p className="text-end bg-primary bg-opacity-30 rounded-full px-3 py-1 text-sm  inline-flex">
                              scheduled
                            </p>
                          </div>
                          <div className="p-4 ">
                            <p className="pm">Pickup: oct 9</p>
                            <p className="pm">12pm - 3pm </p>
                          </div>
                        </li>
                        <li className="shadow-md bg-white gap-3 rounded">
                          <div className="flex justify-end shadow p-4 ">
                            <p className="text-end bg-primary bg-opacity-30 rounded-full px-3 py-1 text-sm  inline-flex">
                              scheduled
                            </p>
                          </div>
                          <div className="p-4 ">
                            <p className="pm">Pickup: oct 9</p>
                            <p className="pm">12pm - 3pm </p>
                          </div>
                        </li>
                      </ul>
                    </div> */}
                    {/* Add Table for order */}
                  </Tab.Panel>
                  <Tab.Panel className="py-10">
                    {pastOrders.length > 0 ? (
                      <div>
                        <Paper sx={{ width: "full", overflow: "hidden" }}>
                          <TableContainer sx={{ maxHeight: 500 }}>
                            <Table
                              className=" "
                              stickyHeader
                              aria-label="sticky table"
                            >
                              <TableHead>
                                <TableRow>
                                  {columns.map((column) => (
                                    <TableCell
                                      key={column.id}
                                      align="left" // Align the headers to the left
                                      style={{
                                        minWidth: column.minWidth,
                                        backgroundColor: "#46B035",
                                        color: "white",
                                        fontSize: "16px",
                                        border: "1px solid #ddd",
                                      }}
                                    >
                                      {column.label}
                                    </TableCell>
                                  ))}
                                </TableRow>
                              </TableHead>

                              <TableBody>
                                {pastOrders?.map((order) => (
                                  <TableRow
                                    hover
                                    role="checkbox"
                                    tabIndex={-1}
                                    key={order._id}
                                  >
                                    {columns.map((column) => {
                                      const value = order[column.id];
                                      return (
                                        <TableCell
                                          key={column.id}
                                          align="left"
                                          style={{
                                            border: "1px solid #ddd",
                                          }}
                                        >
                                          {column.id === "action" ? (
                                            <div>
                                              <button
                                                className="p-1 m-2 rounded-md"
                                                style={{
                                                  backgroundColor: "red",
                                                  color: "white",
                                                  fontSize: "16px",
                                                  border: "1px solid #ddd",
                                                }}
                                                onClick={() =>
                                                  handleDelete(order._id)
                                                }
                                              >
                                                Delete
                                              </button>
                                              <button
                                                className=" p-1 m-2 rounded-lg"
                                                onClick={() =>
                                                  handleEdit(order)
                                                }
                                                style={{
                                                  backgroundColor: "#46B035",
                                                  color: "white",
                                                  fontSize: "16px",
                                                  border: "1px solid #ddd",
                                                }}
                                              >
                                                Edit
                                              </button>
                                            </div>
                                          ) : (
                                            value
                                          )}
                                        </TableCell>
                                      );
                                    })}
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                          <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={pastOrders?.length} // Use the length of AlluserData for pagination
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                          />
                        </Paper>
                      </div>
                    ) : (
                      <div>
                        <h1 className="text-center p-4 text-2xl font-bold">
                          You don’t have any past orders yet.
                        </h1>
                      </div>
                    )}
                    {/* <div className="h-72 flex flex-col justify-center items-center gap-5">
                      <h1 className="text-lg">
                        You don’t have any past orders yet.
                      </h1>
                    </div>
                    <div>
                      <ul className="flex flex-col gap-5">
                        <li className="shadow-md bg-white gap-3 rounded">
                          <div className="flex justify-end shadow p-4 ">
                            <p className="text-end bg-primary bg-opacity-30 rounded-full px-3 py-1 text-sm  inline-flex">
                              Delivered
                            </p>
                          </div>
                          <div className="p-4 ">
                            <p className="pm">Pickup: oct 9</p>
                            <p className="pm">12pm - 3pm </p>
                          </div>
                        </li>
                        <li className="shadow-md bg-white gap-3 rounded">
                          <div className="flex justify-end shadow p-4 ">
                            <p className="text-end bg-primary bg-opacity-30 rounded-full px-3 py-1 text-sm  inline-flex">
                              Delivered
                            </p>
                          </div>
                          <div className="p-4 ">
                            <p className="pm">Pickup: oct 9</p>
                            <p className="pm">12pm - 3pm </p>
                          </div>
                        </li>
                      </ul>
                    </div> */}
                  </Tab.Panel>
                </Tab.Panels>
              </Tab.Group>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
