import React from "react";
import BlogListCard from "../../../../components/AdminPannelComp/BlogComp/BlogListCard";
const AllBlogs = () => {
  return (
    <div>
      <BlogListCard />
    </div>
  );
};

export default AllBlogs;
