import React from "react";

const WelcomePage = () => {
  return (
    <>
      <div className="flex justify-center mt-20 h-screen">
        <h1 className="text-primary text-4xl font-bold">
          Welcome to the Supreme Cleaner
          <h1 className="mt-2 text-primary text-4xl font-bold text-center">
            Admin Dashboard{" "}
          </h1>
        </h1>
      </div>
    </>
  );
};

export default WelcomePage;
