import React from "react";

export default function ThankYou() {
  return (
    <>
      <h1>This is Thankyou page </h1>
      <div>ThankYou</div>
    </>
  );
}
